
export function readSpecifiedCookie(target) {
    function escape(s) { return s.replace(/([.*+?\^${}()|\[\]\/\\])/g, '\\$1'); };
    let match = document.cookie.match(RegExp('(?:^|;\\s*)' + escape(target) + '=([^;]*)'));
    return match ? match[1] : false;
}

/**
 * converts objects within array to specifed key
 * @param {*} array - array of objects
 * @param {*} key - desired key value for object
 * @returns 
 */
export function convertArrayToObject(array, key) {
    return array.reduce(
        (obj, item) => ({
            ...obj,
            [item[key]]: item
        }),
        {}
    );
}

/**
 * good ol Stack Overflow answers, eh?
 * meant for seriealizing new objects but ignoring already serialized sub-objects
 * @param {*} obj 
 * @param {*} stack 
 * @returns 
 */
export function decycle(obj, stack = []) {
    if (!obj || typeof obj !== 'object')
        return obj;

    if (stack.includes(obj))
        return null;

    let s = stack.concat([obj]);

    return Array.isArray(obj)
        ? obj.map(x => decycle(x, s))
        : Object.fromEntries(
            Object.entries(obj)
                .map(([k, v]) => [k, decycle(v, s)]));
}