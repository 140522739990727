<template>
    <div class="qr-code-displayer">
        <div class="content">
            <div class="code-url">{{ details.URL }}</div>

            <div class="canvas-section">
                <canvas :id="'code-qr'"> </canvas>
                <!-- :id="'code-' + details.codeId" -->
            </div>
            <div class="qr-options">
                <div class="option-section" v-on:click="printCode">
                    <v-icon
                        class="icon edit-img option-left"
                        scale="2.5"
                        :name="'print'"
                    ></v-icon>
                    <p class="option-text">Print</p>
                </div>
                <div class="option-section" v-on:click="downloadCode">
                    <v-icon
                        class="icon edit-img option-right"
                        scale="2.5"
                        :name="'file-image'"
                    ></v-icon>
                    <p class="option-text">Save to File</p>
                </div>
            </div>
            <!-- <div class="size-selector">
                <p class="option-text">Size to Save As</p>
                <input class="qr-size" v-model="heightAndWidth" type="number" />
            </div> -->
        </div>
    </div>
</template>

<script>
// import QRCode from "../../node_modules/qrcode/build/qrcode.min.js";
// import store from "../.././store";
import { useStore } from "vuex";

export default {
    name: "QR-Code-Displayer",
    setup() {
        const store = useStore();
    },
    props: {
        // data: {
        //     type: Object,
        //     default: null,
        //     // required: true,
        // },
    },
    data() {
        return {
            displayingLoader: false,
            details: {},
            heightAndWidth: 200,
        };
    },
    mounted() {
        this.details = store.state.data;
        console.log("loaded data", this.details);
        console.log("loaded data", this.details.codeId);

        let opts = {
            errorCorrectionLevel: "H",
            type: "image/jpeg",
            quality: 0.3,
            margin: 1,
            width: 300,
            height: 300,
            // just use defualt
            // color: {
            //     dark: "#010599FF",
            //     light: "#FFBF60FF",
            // },
        };

        // QRCode.toCanvas(
        //     // use ref?
        //     document.getElementById("code-qr"), // + this.details.codeId
        //     this.details.codeId,
        //     opts,
        //     function (error) {
        //         if (error) console.error(error);
        //         console.log("success!");
        //     }
        // );
    },
    methods: {
        downloadCode() {
            // var link = document.getElementById('link');
            // link.setAttribute('download', 'MintyPaper.png');
            // link.setAttribute('href', canvas.toDataURL("image/png").replace("image/png", "image/octet-stream"));
            // link.click();
            // or
            // var image = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");  // here is the most important part because if you dont replace you will get a DOM 18 exception.
            // window.location.href=image; // it will save locally
            // or
            // var canvas = document.getElementById("canvas");
            // var ctx = canvas.getContext("2d");
            // var ox = canvas.width / 2;
            // var oy = canvas.height / 2;
            // ctx.font = "42px serif";
            // ctx.textAlign = "center";
            // ctx.textBaseline = "middle";
            // ctx.fillStyle = "#800";
            // ctx.fillRect(ox / 2, oy / 2, ox, oy);
            // download_img = function(el) {
            // var image = canvas.toDataURL("image/jpg");
            // el.href = image;
            // };
        },
        printCode() {},
    },
};
</script>

<style scoped>
.content {
    display: grid;
    grid-template-columns: 1fr;
    text-align: center;
    align-items: center;
    align-content: center;
    justify-content: center;

    height: 100%;
    width: 100%;
}
.qr-options {
    cursor: pointer;
    padding: 5px 0 5px 0;
    display: grid;
    column-gap: 6px;
    grid-template-columns: 1fr 1fr;
    text-align: center;
    align-items: center;
    align-content: center;
    justify-content: center;
}
.code-url {
    font-size: 2em;
    padding-bottom: 4px;
}
.canvas-section {
}
.option-section {
    background-color: #5f9ea0;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 50px;
    border-radius: 4px;
}
.option-text {
    padding: 0 0 0 3px;
    margin: 0;
}
.size-selector {
    height: 50px;
    grid-template-columns: 1fr;
    text-align: center;
    align-items: center;
    align-content: center;
    justify-content: center;
    /* width: 75px; */
    /* justify-content: center; */
    /* align-self: center; */
}
.qr-size {
    height: 45px;
    text-align: center;
    width: 50%;
}
</style>
