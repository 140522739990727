<template>
    <div class="background" v-on:click="toggled">
        <div class="dropdown-content">
            <div class="dropdown-row">
                <div
                    v-on:click="toggleComponent('/products')"
                    id="sideBox-displayProducts"
                    class="sideBox"
                >
                    Products
                </div>
                <v-icon
                    class="sideBox center-icon"
                    :icon="['fab', 'layer-group']"
                    scale="1.5"
                ></v-icon>
            </div>

            <div class="dropdown-row">
                <div
                    v-on:click="toggleComponent('/transactions')"
                    id="sideBox-displayTransaction"
                    class="sideBox"
                >
                    Transactions
                </div>
                <v-icon
                    class="sideBox center-icon"
                    :icon="['fab', 'cash-register']"
                    scale="1.5"
                    style="color: black"
                ></v-icon>
            </div>

            <div class="dropdown-row">
                <div
                    v-on:click="toggleComponent('/settings/account')"
                    id="sideBox-displayAccountDetails"
                    class="sideBox"
                >
                    Account
                </div>
                <v-icon
                    class="sideBox center-icon"
                    :icon="['fab', 'user-cog']"
                    scale="1.5"
                    style="color: black"
                ></v-icon>
            </div>

            <div class="dropdown-row">
                <div
                    v-on:click="toggleComponent('/settings/company')"
                    id="sideBox-displayCompanyDetails"
                    class="sideBox"
                >
                    Company
                </div>
                <v-icon
                    class="sideBox center-icon"
                    :icon="['fab', 'building']"
                    scale="1.5"
                    style="color: black"
                ></v-icon>
            </div>

            <!-- <div class="dropdown-row">
                <div v-on:click="this.statsPageClick" id="sideBox-displayStats" class="sideBox">Stats</div>
                <v-icon class="sideBox center-icon" name="chart-line" scale="1.5" style="color: black"></v-icon>
            </div> -->

            <div class="dropdown-row">
                <div
                    v-on:click="toggleComponent('/newProduct')"
                    id="sideBox-displayQuickAdd"
                    class="sideBox"
                >
                    Quick Add
                </div>
                <v-icon
                    class="sideBox center-icon"
                    :icon="['fab', 'plus']"
                    scale="1.5"
                    style="color: black"
                ></v-icon>
            </div>

            <div class="dropdown-row">
                <div
                    v-on:click="toggleComponent('/help')"
                    id="sideBox-display-help"
                    class="sideBox"
                >
                    Help
                </div>
                <v-icon
                    class="sideBox center-icon"
                    :icon="['fab', 'question-circle']"
                    scale="1.5"
                    style="color: black"
                ></v-icon>
            </div>

            <div class="dropdown-row escape--row">
                <div id="sideBox-escape" class="sideBox">Close</div>
                <v-icon
                    class="sideBox center-icon"
                    :icon="['fab', 'times']"
                    scale="1.5"
                    style="color: #ebf2ff"
                ></v-icon>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "HomeDropdown",
    data() {
        return {
            // opened: false,
        };
    },
    methods: {
        productsPageClick: function () {
            this.$parent.dropdownOptionSelected("products");
            //   this.closeDropDownMenu();
        },
        // this.$root.emit('');
        transactionsPageClick: function () {
            //this.$root.emit('dropdownOptionSelected', 'transactions');
            this.$parent.dropdownOptionSelected("transactions");
            console.log("stuff should have happen!");
        },
        statsPageClick: function () {
            this.$parent.dropdownOptionSelected("stats");
        },
        companyModalClick: function () {
            this.$parent.dropdownOptionSelected("company");
        },
        accountModalClick: function () {
            this.$parent.dropdownOptionSelected("account");
        },
        quickAddProductClick: function () {
            this.$parent.dropdownOptionSelected("product"); // quickAdd
        },
        helpPageClick: function () {
            this.$parent.dropdownOptionSelected("help");
        },
        closeDropDownMenu: function () {
            this.$parent.dropdownMenuToggled();
        },
        toggled: function () {
            this.$parent.dropdownMenuToggled();
        },
        toggleComponent(desiredComponent) {
            // let routeToUse = "";
            // switch (desiredComponent) {
            //     case "qrDisplayer":
            //         this.activeComponent = qrDisplayer;
            //         data.codeId = data.codeId.replaceAll("/", "-");
            //         routeToUse = `/products/code/${data.codeId}`;
            //         break;
            //     case "EditCodeStatus":
            //         this.activeComponent = EditCodeStatus;
            //         // data.codeId = data.codeId.replaceAll("/", "---");
            //         routeToUse = `/products/code/edit/${encodeURIComponent(
            //             data.codeId
            //         )}?code=${encodeURIComponent(data.codeId)}`;
            //         break;
            //     default:
            //         this.activeComponent = null;
            //         break;
            // }
            this.$parent.dropdownOptionSelected(desiredComponent);

            this.$router.push(desiredComponent); // "/products"
        },
    },
};
</script>

<style scoped>
.background {
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4);
}
.dropbtn {
    background-color: #3b175c;
    color: #ebf2ff;
    padding: 16px;
    font-size: 16px;
    border: none;
    cursor: pointer;
    height: 54px;
}
.dropdown {
    position: relative;
    display: inline-block;
}

/* mobile */
@media (max-width: 670px) {
    .dropdown-content {
        position: absolute;
        background-color: #f1f1f1;
        min-width: 220px;
        width: 100%;
        z-index: 101;
    }
}

/* desktop */
@media (min-width: 670px) {
    .dropdown-content {
        position: absolute;
        background-color: #f1f1f1;
        min-width: 220px;
        z-index: 101;
    }
}

.dropdown-row {
    display: grid;
    grid-gap: 1em;
    grid-template-columns: 2fr 1fr;
    text-align: center;
    align-items: center;
    align-content: center;
    transition-property: background, border-radius;
    transition-duration: 0.2s, 1s;
    transition-timing-function: linear, ease-in;
    transition-delay: 0s, 1s;
    height: 54px;
}
.dropdown-row:hover {
    cursor: pointer;
    background: #bdd8f2;
}
.center-icon {
    align-self: center;
    justify-self: center;
}
.dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}
.dropdown-font {
    float: right;
}
.sideBox {
    display: inline-block;
    padding: 6px 8px 6px 16px;
    text-decoration: none;
    font-size: 25px;
    display: block;
}
.escape--row {
    grid-column: 1/-1;
    background-color: #f03a44;
    color: #ebf2ff;
}
</style>
