<template>
    <div class="product-list-container">
        <div class="product-filter"></div>
        <div class="product" v-for="item in items" v-bind:key="item.productId">
            <product-listing :product="item" />
            <!-- :key="item.version" -->
            <!-- this height on product needs to be updated if codes are added/removed -->
        </div>
    </div>
</template>

<!--<script src="../../node_modules/qrcode/build/qrcode.min.js"></script>-->
<script>
import ProductListing from "./ProductListing.vue";
import { useStore } from "vuex";

// import store from "../.././store";

export default {
    name: "Products",
    // store,
    setup() {
        const store = useStore();
    },
    components: {
        ProductListing,
    },
    data() {
        return {
            items: Object.values(this.$store.getters.getProducts),
        };
    },
    mounted() {
        this.items = Object.values(this.$store.getters.getProducts);
    },
    computed: {
        // ...mapState(["products"]),
        gridStyle() {
            return {
                gridTemplateColumns: `repeat(${this.numberOfColumns}, minmax(100px, 1fr))`,
            };
        },
    },
    methods: {
        addProductCardToggle() {
            this.$parent.quickAddModalOpen = true;
        },
    },
    watch: {
        
        // check for product count and another for deep value change?
        // $store.state.drawer
        // "$store.state.products": {
        //     handler(val) {
        //         console.log("product count change...", val);
        //         // this.items = Object.assign(store.state.products, this.items);
        //         this.items = Object.values(this.$store.state.products);
        //     },
        //     deep: true,
        // },
        // "$store.state.productsVersion": {
        //     handler(val) {
        //         console.log("product version change...", val);
        //         // this.items = Object.assign(store.state.products, this.items);
        //         this.items = Object.values(this.$store.state.products);
        //     },
        //     // deep: true,
        // },
    },
};
</script>

<style>
.product-list-container {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    text-align: center;
    align-items: center;
    align-content: center;
}
.product-filter {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    text-align: center;
    align-items: center;
    align-content: center;
}
.product {
    padding: 2px 0 2px 0;
}
</style>
