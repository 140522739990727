/* eslint-disable */
import router from "./router";
import App from './App.vue';
import { store } from './store';

import LoginModal from './components/account/LoginModal.vue';
// import Icon from 'vue-awesome/components/Icon.vue';
import 'regenerator-runtime/runtime';

import { readSpecifiedCookie, getCurrentUser, logout } from "./auth/auth.js";

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import { library } from "@fortawesome/fontawesome-svg-core";
import * as icons from './icons';

import { createApp, h, configureCompat } from "vue";

const app = createApp({
  render: () => h(App),
  components: {
    LoginModal
  },
  created() {
    console.log('new Vue session rendered - should be only called once...');
    switch (performance.navigation.type) {
      case 0:
        console.info("TYPE_NAVIGATE");
        break;
      case 1:
        console.info("TYPE_RELOAD");
        store.state.pageReload = true;
        break;
      case 2:
        console.info("TYPE_BACK_FORWARD");
        break;
      case 255:
        console.info("255");
        break;
    }
  },
});
app.component("v-icon", FontAwesomeIcon);

// library.add(faBars);
library.add(...Object.values(icons));

app.use(router);
app.use(store);
// Initiate other plugins here

app.mount("#app");

router.beforeEach(async (to, from) => {

  if (to.path) {
    console.log('setting last route', to.path);
    console.log('to', to);
    console.log('from', from);
    localStorage.setItem('lastRoute', to.path);
  } else {
    return;
  }
  // specify and validate needed routes for not getting addntl data on login page completion
  if (to.path === '/CreateAccount') {
    return;
  }
  // if (store.state.transactions && store.state.transactions.length < 1 && !store.state.gettingTransactions) {
  //   console.log('attempting to get any transactions...');
  // }

  try {
    // check this for performance reasons
    if (await getCurrentUser() && to.name !== "Login") {
      // next();
      console.log('going to target route');

      // don't return with updated route, as this resets the navigation and creates infinite loops
      // return { name: `${to.name}` };
    } else {
      // not logged in, send user to login...
      console.log('returning to login page');
      if (to.name !== "Login") {
        // next({ name: 'Login' });
        return { name: "Login" }
      }
      console.log('really should not hit this');
      return;
      // return { name: "Login" };
    }
  } catch (err) {
    console.log('current user or navigation error', err);
  }

  // // need to check for versions of item groups in order to know to pull them in
  // if (store.state.products.length < 1 && !store.state.gettingProducts) {
  //   console.log('attempting to get any products...');
  //   getProducts();

  // }
})
