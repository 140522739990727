<template>
    <div class="qr-code-displayer">
        <div class="content">
            <div class="code-url">{{ code.URL }}</div>
            <div class="code-status">
                Code is currently {{ activeStatusTest }}
            </div>

            <!-- display options for either active/inactive -->
            <div class="code-options">
                <div
                    class="option-section activate"
                    v-on:click="toggleStatus('active')"
                >
                    <v-icon
                        class="icon edit-img option-left"
                        scale="2.5"
                        :name="'check-square'"
                    ></v-icon>
                    <p class="option-text">Enabled</p>
                </div>
                <div
                    class="option-section deactivate"
                    v-on:click="toggleStatus('inactive')"
                >
                    <v-icon
                        class="icon edit-img option-right"
                        scale="2.5"
                        :name="'times-circle'"
                    ></v-icon>
                    <p class="option-text">Disable</p>
                </div>
                <div
                    class="option-section delete delete-option"
                    v-on:click="triggerDelete()"
                >
                    <v-icon
                        class="icon edit-img option-right"
                        scale="2.5"
                        :name="'times-circle'"
                    ></v-icon>
                    <p class="option-text" v-on:click="toggleStatus('DELETE')">
                        DELETE
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { generateId } from "../../services-frontend/fetch-services";
import { RequestObject } from "../../models/RequestObject";
import { useStore } from "vuex";
import { LexRuntime } from "aws-sdk";

export default {
    name: "Modal",
    setup() {
        const store = useStore();
    },
    props: {
        /**
         * if fullPage is true, cover entire window with loader, otherwise
         * just cover parent component with loader and do not fade background
         */
        fullPage: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            code: {},
            product: {},
            localRequestIdArray: [],
        };
    },
    mounted() {
        this.code = store.state.data;
        let pid = window.location.href.substring(
            window.location.href.lastIndexOf("products/") + 9,
            window.location.href.lastIndexOf("/code/")
        );
        this.product = store.state.products[pid];
        console.log("store code", this.code);
    },
    watch: {
        // watch incoming store update for request id, remove upon retrieval
        requestsStack: function (newVal, oldVal) {
            let i, id;
            for (i = 0; i < newVal.length; i++) {
                id = newVal[i].id;

                if (this.localRequestIdArray.includes(id)) {
                    store.dispatch("REMOVE_REQUEST_RESPONSE_FROM_STACK", id);

                    // trigger response result message
                    this.responseResultTransition();

                    // now remove id from component local id array, only need to remove first occurence
                    let index = this.localRequestIdArray.indexOf(id);
                    if (index > -1) {
                        this.localRequestIdArray.splice(index, 1);
                    }
                }
            }
        },
    },
    computed: {
        activeStatusTest() {
            return store.state.data.active ? "Active" : "Inactive";
        },
    },
    methods: {
        // triggerDelete(status) {
        //     if (!status) {
        //         // attempting to enable code
        //         // as in do nothing
        //     } else {
        //         this.code.status = "DELETE";
        //         this.areYouSure("Are you sure you want to delete this code?");
        //     }
        // },
        toggleStatus(status) {
            let alertMsg = "";
            switch (status) {
                case "active":
                    alertMsg = "Are you sure you want to activate this code?";
                    break;
                case "inactive":
                    alertMsg =
                        "Are you sure you want to disable this code. This will redirect inactive codes to the backup URL";
                    break;
                case "DELETE":
                    alertMsg = "Are you sure you want to delete this code?";
                    break;
                default:
                    throw "Illegal Type";
            }
            this.areYouSure(alertMsg, status);
        },
        areYouSure(msg, status) {
            if (confirm(msg)) {
                this.code.status = status;
                this.sendUpdate();

                // now trigger check to wait for the change to appear in the store to acknowledge that
                // the change took placw
            } else {
                // do nothing
            }
        },
        sendUpdate() {
            let req = new RequestObject({
                id: generateId(), // purely front-end request for client loaders
                requestType: "update-item", // or remove-item and then include just the codes to be deleted (if we actually delete codes that is)
                // specificRequest: "new-code", // no check versions!
                payload: {
                    id: this.product.productId,
                    codes: this.code,
                },
            });
            this.localRequestIdArray.push(req.id);
            store.dispatch("REQUEST_UPDATE", req);
        },
    },
};
</script>

<style scoped>
.centered-loader {
    position: fixed;
    z-index: 100;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    min-width: 400px; /* 50% */
    min-height: 50px; /* 50% */
    margin: 10px;
    padding: 20px;
    overflow: scroll;
}
.code-url {
    font-size: 2em;
    padding-bottom: 25px;
}
.code-status {
    /* change font size */
}
.code-options {
    cursor: pointer;
    padding: 5px 2px 5px 2px;
    display: grid;
    column-gap: 6px;
    /* grid-template-columns: 1fr 1fr; */
    grid-template-areas:
        "a"
        "b"
        "c";
    text-align: center;
    align-items: center;
    align-content: center;
    justify-content: center;
}
.option-section {
    background-color: #5f9ea0;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 50px;
    min-width: 300px;
    border-radius: 4px;
    margin-bottom: 15px;
}
.delete-option {
    background-color: indianred;
}
.activate {
    grid-area: "a";
}
.deactivate {
    grid-area: "b";
}
.delete {
    grid-area: "c";
}
</style>
