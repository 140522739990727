<template>
    <div class="filter--bar">
        <div v-on:click="filterBy('received')" :class="filterBy">
            <v-icon icon="cart-plus" scale="2.0"></v-icon>
        </div>
        <div v-bind:class="{ activeFilter: receivedFilter }">
            <v-icon icon="handshake" scale="2.0"></v-icon>
        </div>
        <div :class="filterBy"><v-icon icon="truck" scale="2.0"></v-icon></div>
        <div :class="filterBy"><v-icon icon="flag" scale="2.0"></v-icon></div>
        <div :class="filterBy"><v-icon icon="undo" scale="2.0"></v-icon></div>
        <div :class="filterBy">
            <v-icon icon="history" scale="2.0"></v-icon>
        </div>
        <div v-if="Object.keys(activeFilters).length"></div>
    </div>
</template>

<script>
// import store from '.././store';
import { useStore } from "vuex";

export default {
    name: "FilterTransactions",
    setup() {
        const store = useStore();
    },
    data() {
        return {
            displayFilterTitles: false,
            filteredTransactions: [],
            activeFilters: {},
            receivedFilter: false,
            processingFilter: false,
            shippedFilter: false,
            deliveredFilter: false,
            returnFilter: false,
            specialFilter: false,
        };
    },
    methods: {
        filterBy(filter) {
            if (!activeFilters[filter]) {
                activeFilters[filter] = true;
            } else {
                activeFilters[filter] = false;
            }
        },
    },
    filter() {
        this.filteredTransactions = !this.filteredTransactions.length
            ? store.state.transactions
            : this.filteredTransactions;
    },
};
</script>

<style scoped>
.filter--bar {
    width: 100%;
    height: 60px;
    line-height: 60px;
    padding-top: 10px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: auto;
    align-items: center;
    text-align: center;
    justify-content: center;
    justify-items: center;
}

.filter--title--row {
    columns: 1/-1;
}

.activeFilter {
    background-color: #3b175c;
    color: #fff;
}

/* mobile */
@media (max-width: 670px) {
}

/* desktop */
@media (min-width: 670px) {
}
</style>
