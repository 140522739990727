export {
    faBars,
    faUserCog,
    faSpinner,
    faUsers,
    faCut,
    faBullhorn,
    faPenNib,
    faCircle,
    faPalette,
    faVolumeUp,
    faSmile,
    faGrin,
    faShekelSign,
    faTv,
    faUserTie,
    faFolder,
    faPaintBrush,
    faCircleNotch,
    faSignOutAlt
} from '@fortawesome/free-solid-svg-icons';