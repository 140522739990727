<template>
    <div class="full-page-modal modal" style="display: block">
        <router-view> </router-view>
    </div>
</template>

<script>
// import "vue-awesome/icons";
// import Vue from "vue";
import { mapMutations } from "vuex";

export default {
    name: "FullPageModal",
    components: {},
    props: {
        product: {
            type: Object,
            default: null,
        },
        target: {
            type: Object,
            default: null,
        },
        data: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            opened: false,
        };
    },
    methods: {
        ...mapMutations(["closeOpenModal"]),
        closeModal() {
            this.closeOpenModal();
        },
    },
};
</script>

<style scoped>
.full-page-modal {
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: #fefefe;
}

.close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
    padding-right: 10px;
}
.close:hover,
.close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}
</style>
