<template>
    <div id="transactionsUpdateModal" class="modal" style="display: block">
        <div class="modal-content">
            <div @click="closeModal" class="close-div"><span class="close">&times;</span></div>
            <div class="">
                Transactions
            </div>
            <div class="transactions--update--list">
                <div v-for="transaction in transactions" v-bind:key="transaction.id" class="transactions--update--list--row" >
                    <TransactionView :transaction="transaction" />
                </div>
            </div>

            <!-- :style="{ height: heightForStatusSection + 'px' }" -->
            <div class="update--status--section">
                <div class="update--status--section--header">
                    Update Status
                </div>
                <div class="update--status--section--buttons">
                    <div ><v-icon icon="handshake" scale="2.0"></v-icon></div>
                    <div :class="filterBy"><v-icon icon="truck" scale="2.0"></v-icon></div>
                    <div :class="filterBy"><v-icon icon="flag" scale="2.0"></v-icon></div>
                    <div :class="filterBy"><v-icon icon="undo" scale="2.0"></v-icon></div>
                    <div :class="filterBy"><v-icon icon="history" scale="2.0"></v-icon></div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>

// import TransactionView from './TransactionView.vue';
import TransactionView from './TransactionView.vue';
import store from '.././store';

export default {
  name: 'TransationsUpdateModal',
  components: {
      TransactionView
  },
  props: [ 'transactions' ],
  data() {
    return {
      showLoadingIcon: false,
      errorMsg: '',
      heightForStatusSection: 0
    };
  },
  watch: {

  },
  methods: {
    closeModal () {
        this.$parent.closeAnyModal();
    },
    filterBy () {
        return null;
    }
  },
};
</script>

<style scoped>
    .modal {
        position: fixed;
        z-index: 1;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        overflow: auto;
        background-color: rgb(0,0,0);
        background-color: rgba(0,0,0,0.4);
    }

    .modal-title{
        font-size: 1.5em;
        color: black;
        text-align: center;
    }

    .modal-header{
        text-align: center;
    }


    .modal-content {
        background-color: #fefefe;
        width: 100%;
        height: 100%;
        display: grid;
        /* grid-gap: 1em; */
        grid-template-columns: 1fr;
        text-align: center;
        align-items: center;
        align-content: center;
        font-size: 1.5em;
        padding: 0;
        position: fixed;
        bottom: 0%;
        width: 100%;
    }
    /* mobile */
    /* @media (max-width: 670px) {
        .modal-content {
          background-color: #fefefe;
          width: 100%;
          height: 100%;
          display: grid;
          grid-gap: 1em;
          grid-template-columns: 1fr;
          text-align: center;
          align-items: center;
          align-content: center;
          font-size: 1.5em;
          padding: 0;
          position: fixed;
          bottom: 0%;
          width: 100%;
        }
    } */

    /* desktop */
    /* @media (min-width: 670px) {
        .modal-content {
          background-color: #fefefe;
          margin: 15% auto;
          width: 100%;
          height: 100%;
          display: grid;
          grid-gap: 1em;
          grid-template-columns: 1fr;
          text-align: center;
          align-items: center;
          align-content: center;
          font-size: 1.5em;
          position: fixed;
          bottom: 0%;
        }
    } */

    .transactions--update--list {
        display: grid;
        grid-template-columns: 1fr;
        gap: 0px;
        text-align: center;
        align-items: center;
        align-content: center;
        background-color: lightgray;
    }

    .transactions--update--list--row {
        display: grid;
        /* grid-gap: 1em; */
        grid-template-columns: 1fr;
        text-align: center;
        align-items: center;
        align-content: center;
    }

    .transactions--update--list--row--dropdown--content {
        display: grid;
        /* grid-gap: 1em; */
        grid-template-columns: 1fr 1fr;
        text-align: center;
        align-items: center;
        align-content: center;
    }

    .update--status--section--buttons {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        text-align: center;
        align-items: center;
        align-content: center;
    }

    .text-field-sub-group {
        display: grid;
        grid-template-columns: 1fr 50px;
        text-align: center;
        align-items: center;
        align-content: center;
    }

    .center-icon {
        align-self: center;
        justify-self: center;
        padding-left: 20px;
    }

    .title-text {
        grid-column: 1 / 3;
    }

    .submit-button {
        font-size: 1.3em;
        cursor: pointer;
        border-radius: 18px;
        background-color: lightblue;
        border: solid 1px transparent;
        padding-left: 20px;
        padding-right: 20px;
        transition-property: background, border-radius;
        transition-duration: .2s, 1s;
        transition-timing-function: linear, ease-in;
        transition-delay: 0s, 1s;
    }
    .submit-button:hover {
        cursor: pointer;
        background: #DEE602;
    }

    .error-section {
        background-color: lightcoral;
    }

    .modal-header{
      text-align: center;
  }

  .modal-font-plus{
      background-color: #0eacf9;
      border-radius: 50%;
      padding: 10px;
      overflow: visible;
  }

  .toggle-edit{
      height: 35px;
      border: solid;
      color: white;
      border-color: #3b175c;
      border-width: 1px;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      text-align: center;
      line-height: 35px;
      background-color: #3b175c;
  }

  .toggle-edit:hover {
      cursor: pointer;
      background-color: goldenrod;
      border-color: goldenrod;
      animation: shake 0.5s;
      animation-iteration-count: infinite;
  }

  .modal-textfield {
        background-color: lightgray;
        text-indent: 20px;
        font-size: 1.1em;
        color: black;
        padding-top: 3px;
        padding-bottom: 3px;
        height: 100%;
        width: 100%;
        border-radius: 13px;
        border-left: solid;
        border-top: solid;
        border-bottom: solid;
        border-color: lightgray;
        /* border-width: 1px; */
  }

  .reset-element{
      height: 25px;
      color: white;
      text-align: center;
      line-height: 25px;
      border-top-right-radius: 5px;
      border-top-left-radius: 5px;
      background-color: gray;
  }

    .close {
        color: #aaa;
        float: right;
        font-size: 28px;
        font-weight: bold;
        padding-right: 10px;
    }

    .close:hover,
    .close:focus {
        color: black;
        text-decoration: none;
        cursor: pointer;
    }
</style>
