/* eslint-disable no-unused-expressions */
/* eslint-disable no-console */
// import Vue from 'vue';
import { createStore } from 'vuex'
// import Vuex from 'vuex';
// import { fetchRequest, generateId } from "./services-frontend/fetch-services";
import { updateProduct, getProducts, getSpecifiedProducts } from "./services-frontend/product-services";

const functionMap = {
  "update-item": updateProduct,
  "get-products": getProducts,
  "get-items": getSpecifiedProducts,
  "get-specified-products": getSpecifiedProducts,
  // "getUpdatedProdcuts": getUpdatedProducts
}

const tokenCookieMapping = {
  "idToken": "idCookie",
  "accessToken": "accessCookie",
  "refreshToken": "refreshCookie",
  "username": "usernameCookie"
}

// export default new Vuex.Store({
export const store = createStore({
  state: {
    openModal: '',
    currentUser: '',

    // should be purely managed with cookies
    // tokens: {
    //   idToken: '',
    //   accessToken: '',
    //   refreshToken: '',
    // },
    // idToken: '',  // todo - remove
    // accessToken: '',  // todo - remove
    // refreshToken: '', // todo - remove
    initialTransactionsLoaded: false,
    transactions: [],
    filteredTransactions: [],
    archivedTransactions: [],
    previousSearchedTransactions: [],
    products: {},
    productsVersion: 0,
    archivedProducts: [],
    cookieArray: [],
    pageReload: false,
    data: {},
    forceCloseModals: 0,

    // requestsAwaitingConfirmation contains objects: {id: 1111, result: 'success', msg: 'something'}
    requestsAwaitingConfirmation: [],
    requestsObjectsAwaitingConfirmation: [],
    requestsStack: [],
    requestsObj: {
      version: 0
    },

    // fullPageModalActive: false,
  },
  getters: {
    getCookies(state) {
      return;
    },
    getTokens(state) {
      return state.tokens;
    },
    getProducts(state) {
      return state.products;
    },
    getProductsVersion(state) {
      return state.productsVersion;
    },
    getPageReloadStatus(state) {
      return state.pageReload;
    },
    getRequestObject(state) {
      return state.requestsObj;
    },
    getRequestById: (state) => (id) => {
      return state.requestsObj[id];
    }
  },
  // mutations handles setters
  // setters: {

  // },
  mutations: {
    // probablty should be handled only within auth service file
    // setCookies(state, cookie) {
    //   state.
    // },
    setPageReload(state, boolean) {
      state.pageReload = boolean;
    },
    async submitUpdate(state, request) {

      let response;
      state.requestsObj[request.id] = {};
      Vue.set(state.requestsObj, request.id, {
        type: request.requestType
      })
      state.requestsObj.version += 1;
      try {
        console.log('submitting update request', request);
        // if call was successful, fetch-services handle the clearing of requestsAwaitingConfirmation
        response = await functionMap[request.requestType](request);
      } catch (err) {

        if (state.requestsObj[request.id]) {
          // set message and delete
          state.requestsObj[request.id].msg = "Error Detected"
          delete state.requestsObj[request.id];
        }
        console.log('failure to call function...', err);
        return;
      }
    },
    updateRequestObject(state, id) {

    },
    resolveRequest(state, id) {

    },
    /**
     * gets all last updated products with newer lastUpdated dates than the current
     * session. If array is empty, no newer products are available
     */
    updateCheck() {
      // handles socket-like connection checks
      // should get called every seoncd to check server-side for any company id related
      // updates
    },
    removeRequestId(state, req) {
      if (state.requestsObjectsAwaitingConfirmation[req.id]) {
        delete state.requestsObjectsAwaitingConfirmation[req.id];
      }
    },
    /**
     * removed an element based on id from the requestsStack
     * @param {*} state 
     * @param {*} id 
     */
    removeRequestResponseFromStack(state, id) {
      let i;
      for (i = 0; i < state.requestsStack.length; i++) {
        if (state.requestsStack[i].id === id) {
          state.requestsStack.splice(i, 1);
          break;
        }
      }
      if (state.requestsObj[id]) {
        delete state.requestsObj[id];
      }
    },
    closeOpenModal() {
      this.openModal = '';
    },
    // setToken(state, token, whichToken){

    // },
    setPageReloadState(state, flag) {
      state.pageReload = flag;
    },
    setUser(username) {
      const today = new Date();
      const expire = new Date();
      expire.setDate(today.getDate() + 1);
      document.cookie = `usernameCookie=${username}';expires='${expire.toUTCString()}`;
    },
    setTokens(state, tokens) {

      console.log('setting tokens', tokens);
      // clear current cookies related to user login
      document.cookie = 'usernameCookie=;idCookie=;refreshCookie=;accessCookie=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';

      const today = new Date();
      const expire = new Date();
      expire.setDate(today.getDate() + 1);
      let cookieString = '';
      Object.keys(tokens).forEach(token => {
        console.log('token', token);
        cookieString += `${tokenCookieMapping[token]}=;`
        document.cookie = `${tokenCookieMapping[token]}=${tokens[token]}';expires='${expire.toUTCString()}`;
      })
      // cookieString += ' expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';

      // clear current cookies related to user login
      // document.cookie = 'usernameCookie=;idCookie=;refreshCookie=;accessCookie=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';

      // document.cookie = `idCookie=${id}';expires='${expire.toUTCString()}`;
      // document.cookie = `accessCookie=${access}';expires='${expire.toUTCString()}`;
      // document.cookie = `refreshCookie=${refresh}';expires='${expire.toUTCString()}`;
      // document.cookie = `usernameCookie=${this.currentUser}';expires='${expire.toUTCString()}`;
      // this.idToken = id;
      // this.accessToken = access;
      // this.refreshToken = refresh;
      console.log('logged in...');
    },
    // readSpecifiedCookie(target) {
    //   const allcookies = document.cookie;
    //   this.cookieArray = allcookies.split(';');
    //   const cookieName = target;
    //   const re = new RegExp(`[; ]${cookieName}=([^\\s;]*)`);
    //   const sMatch = (`${document.cookie}`).match(re);
    //   if (cookieName && sMatch) {
    //     return unescape(sMatch[1]);
    //   }
    //   return 'not found';
    // },
    setProducts(state, data) {
      data.forEach(product => {
        if (!state.products[product.productId]) {
          // product does not exist, add to object
          state.products[product.productId] = {};
          state.products[product.productId] = Object.assign(product, {});
        } else {
          // if(product.version > state.products[product.productId].version) {
          if (product.version && state.products[product.productId].version <= product.version) {
            // new version available, update product to current
            state.products[product.productId] = Object.assign(state.products[product.productId], product);
          }
        }
      });
      state.productsVersion++;
      console.log('loaded products', state.products);
    },
    closeAllModals(state) {
      state.forceCloseModals++;
    }
  },
  actions: {
    UPDATE_TOKENS(context, tokens) {
      this.commit('setTokens', tokens);
    },
    SET_PAGE_RELOAD_STATE(context, flag) {
      this.commit('setPageReloadState', flag);
    },
    SET_PRODUCTS(context, data) {
      this.commit('setProducts', data);
    },
    SET_USER(context, username) {
      this.commit('setUser', username);
    },
    CLOSE_MODALS(context) {
      this.commit('closeAllModals');
    },
    async REQUEST_UPDATE(context, data) {
      this.commit('submitUpdate', data);
    },
    REMOVE_REQUEST_ID(context, req) {
      this.commit('removeRequestId', req);
    },
    REMOVE_REQUEST_RESPONSE_FROM_STACK(context, id) {
      this.commit('removeRequestResponseFromStack', id);
    }
  },
});
