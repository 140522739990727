<template>
    <div id="quickAddModal" class="modal" style="display: block">
        <form id="quickAddForm" v-on:submit.prevent action="" class="form-element" method="post">
            <div class="modal-content modal-input">
                <div></div>
                <div @click="closeModal" class="close-div"><span id="closeQuickAddModal" class="close">&times;</span></div>
                <div class="title-text">
                    Quick Add Product
                </div>

                <label for="qa-product-name" class="">Product Name</label>
                <div class="text-field-sub-group">
                    <input v-model="productName" required="" class="modal-textfield" type="text" placeholder="product name">
                    <v-icon class="center-icon" name="box" scale="1.5" style="color: black"></v-icon>
                </div>

                <label for="qa-product-name" class="">Description</label>
                <div class="text-field-sub-group">
                    <input v-model="productDescription" required="" class="modal-textfield" type="text" placeholder="product description">
                    <v-icon class="center-icon" name="align-left" scale="1.5" style="color: black"></v-icon>
                </div>

                <label for="qa-product-name" class="">SKU</label>
                <div class="text-field-sub-group">
                    <input v-model="productSku" required="" class="modal-textfield" type="text" placeholder="product SKU">
                    <v-icon class="center-icon" name="key" scale="1.5" style="color: black"></v-icon>
                </div>

                <label for="qa-product-name" class="">Price (USD)</label>
                <div class="text-field-sub-group">
                    <input v-model="productPrice" required="" class="modal-textfield" type="text" placeholder="$0.00">
                    <v-icon class="center-icon" name="dollar-sign" scale="1.5" style="color: black"></v-icon>
                </div>

                <div class="form-group form-element title-text">
                    <button @click="addNewProduct" type="submit" class="btn btn-primary btn-block submit-button">Create</button>
                </div>

                <div class="form-group form-element title-text error-section">
                    <div v-if="errorMsg != ''">{{errorMsg}}</div>
                </div>

                <div v-if="showLoadingIcon" class="loading title-text">
                    <i class="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import store from '.././store';
export default {
  name: 'QuickAdd',
  components: { },
  data() {
    return {
      opened: false,
      productName: '',
      productPrice: 0.00,
      productDescription: '',
      productSku: '',
      showLoadingIcon: false,
      isActivePriceInput: false,
      errorMsg: ''
    };
  },
  watch: {
        productName: function () {
            this.productName = this.productName.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '');
        },
        productSku: function () {
            this.productSku = this.productSku.replace(/[&\/\\#, +()$~%.'":*?<>{}]/g, '');
        },
        productDescription: function () {
            this.productDescription = this.productDescription.replace(/[&\/\\#()$~%'":*<>{}]/g, '');
        },
  },
  mounted () {
  },
  methods: {
    closeModal () {
        console.log('closing quick add modal');
        this.$parent.closeAnyOpenModal();
    },
    stripTheGarbage(e) {
      if (e.keyCode < 48 && e.keyCode !== 46 || e.keyCode > 59) {
        e.preventDefault()
      }
    },
    formatDollars() {
      if (this.productPrice != '') {
        var num = this.productPrice;

        num = Number(num);
        let countDecimals = function (value) {
          if(Math.floor(value) === value) return 0;
          return value.toString().split(".")[1].length || 0;
        }
        let decimal = countDecimals(num);
        if (decimal < 2) {
          num = num.toFixed(2)
        }
        if (decimal > 2) {
          num = num.toFixed(decimal)
        }
        if (parseInt(num) < 1) {
          num = "." + String(num).split(".")[1];
        }
        this.productPrice = num;
      }
    },
    clearFields () {
        this.productName = '';
        this.productDescription = '';
        this.productPrice = 0.00;
    },
    validatePrice () {
        if (this.isInputActive) {
            return this.value.toString()
        } else {
            return "$ " + this.value.toFixed(2).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")
        }
    },

    async addNewProduct () {
        if (![this.productName, this.productDescription, this.productSku].every(Boolean)) {
            this.errorMsg = "Some value is missing!";
            return false;
        } else {
            this.errorMsg = '';
        }

        let result;
        this.showLoadingIcon = true;

        // console.log(`https://hb3fbkhpul.execute-api.us-east-1.amazonaws.com/dev?idToken=' + ${idCookie} + "&username=" + ${usernameCookie} + "&productName=" + ${productName} + "&price=" + ${price} + "&description=" + ${description}`);
        try {
            // wait
            // productQuickAdd Api Gateway
            // /service/create_product/*
            // https://hb3fbkhpul.execute-api.us-east-1.amazonaws.com/dev?idToken=' + store.idToken + "&username=" + store.currentUser + "&productName=" + this.productName + "&price=" + this.productPrice + "&description=" + this.productDescription
            // result = await fetch('https://sounal/service/create_product?productName=' + this.productName + "&price=" + this.productPrice + "&description=" + this.productDescription)
            //     .then(function(response) {
            //         if(response.ok){
            //             return response;
            //         }
            //         return false;
            //     });
        } catch(err) {
            console.error("adding new product error detected", err);
            this.errorMsg = "An error occured";

        } finally {
            this.showLoadingIcon = false;
        }
        console.log("response from adding new product", result);
        if (!result) {
            this.errorMsg = "An error occured";
            return false;
        }
        this.errorMsg = '';

        const newProd = {
            name: this.productName,
            price: this.productPrice,
            description: this.productDescription,
        };
        this.$parent.addProduct();
        this.closeModal();
        this.$parent.displaySuccessMsg();
    },
  },
};
</script>

<style scoped>
    .modal {
        position: fixed;
        z-index: 1;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        overflow: auto;
        background-color: rgb(0,0,0);
        background-color: rgba(0,0,0,0.4);
    }

    .modal-title{
        font-size: 1.5em;
        color: black;
        text-align: center;
    }

    .modal-header{
        text-align: center;
    }

    .modal-content {
        background-color: #fefefe;
        margin: 15% auto;
        padding: 20px;
        border: 1px solid #888;
        width: 80%;

        display: grid;
        grid-gap: 1em;
        grid-template-columns: 1fr 3fr;
        text-align: center;
        align-items: center;
        align-content: center;

        font-size: 1.5em;
    }

    .text-field-sub-group {
        display: grid;
        grid-template-columns: 1fr 50px;
        text-align: center;
        align-items: center;
        align-content: center;
    }

    .center-icon {
        align-self: center;
        justify-self: center;
        padding-left: 20px;
    }

    .title-text {
        grid-column: 1 / 3;
    }

    .submit-button {
        font-size: 1.3em;
        cursor: pointer;
        border-radius: 18px;
        background-color: lightblue;
        border: solid 1px transparent;
        padding-left: 20px;
        padding-right: 20px;
        transition-property: background, border-radius;
        transition-duration: .2s, 1s;
        transition-timing-function: linear, ease-in;
        transition-delay: 0s, 1s;
    }
    .submit-button:hover {
        cursor: pointer;
        background: #DEE602;
    }

    .error-section {
        background-color: lightcoral;
    }

    .modal-header{
      text-align: center;
  }

  .modal-font-plus{
      background-color: #0eacf9;
      border-radius: 50%;
      padding: 10px;
      overflow: visible;
  }

  .toggle-edit{
      height: 35px;
      border: solid;
      color: white;
      border-color: #3b175c;
      border-width: 1px;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      text-align: center;
      line-height: 35px;
      background-color: #3b175c;
  }

  .toggle-edit:hover {
      cursor: pointer;
      background-color: goldenrod;
      border-color: goldenrod;
      animation: shake 0.5s;
      animation-iteration-count: infinite;
  }

  .modal-textfield {
        background-color: lightgray;
        text-indent: 20px;
        font-size: 1.1em;
        color: black;
        padding-top: 3px;
        padding-bottom: 3px;
        height: 100%;
        width: 100%;
        border-radius: 13px;
        border-left: solid;
        border-top: solid;
        border-bottom: solid;
        border-color: lightgray;
        /* border-width: 1px; */
  }

  .reset-element{
      height: 25px;
      color: white;
      text-align: center;
      line-height: 25px;
      border-top-right-radius: 5px;
      border-top-left-radius: 5px;
      background-color: gray;
  }

    .close {
        color: #aaa;
        float: right;
        font-size: 28px;
        font-weight: bold;
    }

    .close:hover,
    .close:focus {
        color: black;
        text-decoration: none;
        cursor: pointer;
    }
</style>
