<template>
    <div>
        <div class="transaction-holder">
            <div class="table--row--grid--filters">
                <div class="checkbox--container--header">
                    <div
                        v-bind:class="{ 'checkbox--div--active': allActive }"
                        class="checkbox--div--header"
                        v-on:click="toggleAll"
                    >
                        <div v-if="allActive" class="check"></div>
                    </div>
                </div>
                <FilterTransactions />
            </div>
            <div
                class="table-row-grid"
                v-for="(transaction, index) in sortedTransactions"
                v-bind:key="transaction.transactionId"
            >
                <div
                    class="month--row"
                    v-if="
                        checkTimeSplit(
                            'month',
                            sortedTransactions,
                            index,
                            transaction.status.current.datetime
                        )
                    "
                >
                    {{
                        displayFormattedTime(
                            "month",
                            transaction.status.current.datetime
                        )
                    }}
                </div>
                <div
                    class="day--row"
                    v-if="
                        checkTimeSplit(
                            'day',
                            sortedTransactions,
                            index,
                            transaction.status.current.datetime
                        )
                    "
                >
                    {{
                        displayFormattedTime(
                            "day",
                            transaction.status.current.datetime
                        )
                    }}
                </div>
                <div class="checkbox--container">
                    <div
                        class="checkbox--div"
                        v-on:click="checkboxToggle(transaction.transactionId)"
                    >
                        <div
                            v-if="
                                selectedTransactions.includes(
                                    transaction.transactionId
                                )
                            "
                            class="check"
                        ></div>
                    </div>
                </div>
                <div>{{ transaction.name }}</div>
                <div>{{ transaction.priceSold }}</div>
                <div>
                    {{
                        transactionTypeMapping[transaction.status.current.value]
                    }}
                </div>

                <transition name="fade">
                    <div
                        v-if="
                            selectedTransactions.length &&
                            index === sortedTransactions.length - 1
                        "
                        class="update--selected--transactions pulse--anim"
                        v-on:click="displayTransactionPage"
                    >
                        Update/View
                    </div>
                    <div
                        class="spacer"
                        v-else-if="index === sortedTransactions.length - 1"
                    ></div>
                </transition>
            </div>
            <br />

            <TransactionsUpdateModal
                v-if="displayTransactionsModal"
                :transactions="transactionsSelectedToBeUpdated"
            />
        </div>
        <div class="footer">
            <div class="transaction--pagination">
                <button
                    class="
                        pagination--button
                        transaction--page--btn
                        left--arrow
                    "
                    @click="previousPageJump"
                >
                    <i class="arrow left-arrow"></i
                    ><i class="arrow left-arrow"></i>
                </button>
                <button
                    class="
                        pagination--button
                        transaction--page--btn
                        left--arrow
                    "
                    @click="prevPage"
                >
                    <i class="arrow left-arrow"></i>
                </button>
                <div class="pagination--button--holder">
                    <input
                        class="pagination--input"
                        v-model="currentPage"
                        type="number"
                        min="1"
                        max="100"
                    />
                    <div class="pagination--total--pages">
                        <div class="pagination--total--pages--content">
                            of {{ totalPages }}
                        </div>
                    </div>
                </div>
                <button
                    class="
                        pagination--button
                        transaction--page--btn
                        right--arrow
                    "
                    @click="nextPage"
                >
                    <i class="arrow right-arrow"></i>
                </button>
                <button
                    class="
                        pagination--button
                        transaction--page--btn
                        right--arrow
                    "
                    @click="nextPageJump"
                >
                    <i class="arrow right-arrow"></i
                    ><i class="arrow right-arrow"></i>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import TransactionsUpdateModal from "./TransactionsUpdateModal.vue";
import TransactionsView from "./TransactionsView.vue";
import FilterTransactions from "./FilterTransactions.vue";
// import store from ".././store";
import { useStore } from "vuex";

// import 'vue-awesome/icons';

let currentDayTracker = null;
let currentMonthTracker = null;

const addZero = (value) => ("0" + value).slice(-2);
const formatDate = (value) => {
    if (value) {
        const dt = new Date(value);
        return `${addZero(dt.getDate())}/${addZero(
            dt.getMonth() + 1
        )}/${dt.getFullYear()}`;
    }
    return "";
};

let dateMapping = {
    0: "Jan",
    1: "Feb",
    2: "Mar",
    3: "Apr",
    4: "May",
    5: "Jun",
    6: "Jul",
    7: "Aug",
    8: "Sep",
    9: "Oct",
    10: "Nov",
    11: "Dec",
};

export default {
    name: "TransactionTable",
    setup() {
        const store = useStore();
    },
    data() {
        return {
            transactions: store.state.transactions,
            currentSort: "transactionDate",
            currentSortDir: "asc",
            pageSize: 20, // 20
            currentPage: 1,
            displayTransactionsModal: false,
            selectedTransactions: [],
            selectAllOnCurrentPage: false,
            allActive: false,
            transactionTypeMapping: {
                orderReceived: "Received",
                processed: "Processed",
                shipped: "Shipped",
                delivered: "Delivered",
                return: "Return",
                special: "Special",
            },
        };
    },
    components: {
        TransactionsUpdateModal,
        TransactionsView,
        FilterTransactions,
    },
    methods: {
        closeAnyModal() {
            this.displayTransactionsModal = false;
        },
        toggleAll() {
            this.allActive = this.allActive ? false : true;
            if (this.allActive) {
                let IDs = [];
                this.sortedTransactions.forEach((item) => {
                    // IDs.push(item.transactionId);
                    IDs.push(item.transactionId);
                });
                this.selectedTransactions = IDs;
            } else {
                this.selectedTransactions = [];
            }
        },
        checkboxToggle(id) {
            if (this.selectedTransactions.includes(id)) {
                this.selectedTransactions = this.selectedTransactions.filter(
                    (item) => item !== id
                );
            } else {
                this.selectedTransactions.push(id);
            }
        },
        sort(s) {
            if (s === this.currentSort) {
                this.currentSortDir =
                    this.currentSortDir === "asc" ? "desc" : "asc";
            }
            this.currentSort = s;
        },
        nextPage() {
            if (typeof this.currentPage === "string") {
                this.currentPage = parseInt(this.currentPage);
            }
            if (
                this.currentPage * this.pageSize <
                store.state.transactions.length
            ) {
                this.currentPage += 1;
            }
        },
        prevPage() {
            if (typeof this.currentPage === "string") {
                this.currentPage = parseInt(this.currentPage);
            }
            if (this.currentPage > 1) {
                this.currentPage -= 1;
            }
        },
        nextPageJump() {
            if (typeof this.currentPage === "string") {
                this.currentPage = parseInt(this.currentPage);
            }
            if (this.currentPage * this.pageSize * 5 < this.totalPages) {
                this.currentPage += 5;
            } else {
                this.currentPage = this.totalPages;
            }
        },
        previousPageJump: function () {
            if (typeof this.currentPage === "string") {
                this.currentPage = parseInt(this.currentPage);
            }
            if ((this.currentPage * this.pageSize) / 5 < 1) {
                this.currentPage = 1;
            } else {
                this.currentPage -= 5;
            }
        },
        goToPage: function () {},
        displayFormattedTime(measure, date) {
            let formattedDate = new Date(date);
            switch (measure) {
                case "day":
                    let dayResponse = formattedDate.getDate();
                    if (dayResponse < 10) {
                        dayResponse = `0${dayResponse}`;
                    }
                    return dayResponse;
                    break;
                case "month":
                    return `${
                        dateMapping[formattedDate.getMonth()]
                    } ${formattedDate.getFullYear()}`;
                    break;
                default:
                    break;
            }
        },
        checkTimeSplit(measure, transactions, index, date) {
            switch (measure) {
                case "day":
                    if (transactions[index - 1]) {
                        if (
                            transactions[index - 1].status.current.datetime !==
                            transactions[index].status.current.datetime
                        ) {
                            return true;
                        }
                    } else {
                        return true;
                    }
                    break;
                case "month":
                    if (transactions[index - 1]) {
                        if (
                            transactions[index - 1].status.current.datetime
                                .split("-")
                                .slice(0, 2)
                                .join("-") !==
                            transactions[index].status.current.datetime
                                .split("-")
                                .slice(0, 2)
                                .join("-")
                        ) {
                            return true;
                        }
                    } else {
                        return true;
                    }
                    break;
                default:
                    break;
            }

            return false;
        },
        displayTransactionPage() {
            this.displayTransactionsModal = true;
        },
    },
    watch: {
        selectAllOnCurrentPage: function (newVal, oldVal) {
            if (newVal) {
                this.sortedTransactions.forEach((transaction) => {
                    this.selectedTransactions.push(transaction.transactionId);
                });
            } else {
                this.selectedTransactions = [];
            }
        },
    },
    computed: {
        transactionsSelectedToBeUpdated() {
            if (this.selectedTransactions) {
                let selectedFullTransactions = [];
                this.sortedTransactions.forEach((transaction) => {
                    if (
                        this.selectedTransactions.includes(
                            transaction.transactionId
                        )
                    ) {
                        selectedFullTransactions.push(transaction);
                    }
                });
                return selectedFullTransactions;
            }
            return [];
        },
        totalPages: function () {
            // needs to be set to whatever filter
            if (store.state.transactions) {
                return Math.ceil(
                    store.state.transactions.length / this.pageSize
                );
            }
            return 1;
            // if (!this.sortedTransactions && this.sortedTransactions.length < 1) {
            //   return Math.ceil(store.state.transactions.length / this.pageSize);
            // } else {
            //   console.log('sorrted length', this.sortedTransactions);
            //   console.log(Math.ceil(this.sortedTransactions.length / this.pageSize));
            //   return Math.ceil(this.sortedTransactions.length / this.pageSize);
            // }
        },
        sortedTransactions: function () {
            return store.state.transactions
                .sort((a, b) => {
                    let modifier = 1;
                    if (this.currentSortDir === "desc") modifier = -1;
                    if (a[this.currentSort] < b[this.currentSort]) {
                        return -1 * modifier;
                    }
                    if (a[this.currentSort] > b[this.currentSort]) {
                        return 1 * modifier;
                    }
                    return 0;
                })
                .filter((row, index) => {
                    let start = (this.currentPage - 1) * this.pageSize;
                    let end = this.currentPage * this.pageSize;
                    if (index >= start && index < end) {
                        return true;
                    }
                });
        },
    },
};
</script>

<style>
input {
    width: 100%;
    display: block;
}
body {
    color: #444;
    font: 100%/30px "Helvetica Neue", helvetica, arial, sans-serif;
    text-shadow: 0 1px 0 #fff;
}

.table--row--grid--filters {
    width: 100%;
    display: grid;
    grid-template-columns: 50px 1fr;
    grid-template-rows: auto;
    align-items: center;
    justify-content: center;
    padding-bottom: 10px;
}

.header--checkbox {
}

.table-row-grid {
    display: grid;
    grid-template-columns: 13% 1fr 20% 25%;
    grid-template-rows: auto;
    row-gap: 5px;
    align-items: center;
    justify-content: center;
    padding-bottom: 1vh;
    /* bottom:; */
    /* padding-bottom: 5vh;  testing for scrolling to bottom */
}
.spacer {
    padding-bottom: 4vh;
}
.day--row {
    grid-column: 1/-1;
    padding-left: 30px;
    text-align: left;
}

.month--row {
    grid-column: 1/-1;
    font-size: 20px;
    font-weight: bold;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
}
.checkbox--container--header {
    /* height: 100%; */
    padding-left: 5px;
}
.checkbox--container {
    height: 100%;
    padding-left: 5px;
}
.checkbox--div--header {
    height: 40px;
    background-color: white;
    color: whitesmoke;
    border: 1px solid navy;
    text-align: center;
    content: "";
    border-radius: 3px;
}
.checkbox--div {
    max-width: 45px;
    height: 45px;
    background-color: white;
    color: whitesmoke;
    border: 1px solid navy;
    text-align: center;
    content: "";
    border-radius: 3px;
}
.checkbox--div--active {
    content: "✓";
    /* background-color: #3b175c; */
}
input[type="checkbox"]::after {
    /* display: block; */
    content: "";
    width: 100%;
    min-width: 50%;
    min-height: 22px;
    line-height: 22px;
    background-color: white;
    color: whitesmoke;
    border: 1px solid navy;
    text-align: center;
    /* padding-bottom: 10px; */
}
input[type="checkbox"]:checked::after {
    content: "✓";
    /* background-color: #3b175c; */
}

.check {
    display: inline-block;
    transform: rotate(45deg);
    height: 24px;
    width: 12px;
    border-bottom: 7px solid #78b13f;
    border-right: 7px solid #78b13f;
}

/* Table row coloring */
.even {
    background: #eee;
}
.odd {
    background: #ff0;
}

#TransactionTable .items-per-page {
    height: 100%;
    display: flex;
    align-items: center;
    color: #337ab7;
}

#TransactionTable .items-per-page label {
    margin: 0 15px;
}

.footer {
    position: fixed;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
    align-items: center;
    justify-content: center;
    bottom: 0px;
    width: 100%;
    overflow: hidden;
}

.transaction--pagination {
    grid-column: 1/-1;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: auto;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 8vh;
    min-width: 300px;
    overflow: hidden;
    bottom: 0;
}

.pagination--button {
    width: 100%;
    height: 100%;
    border: none;
}

.pagination--button:focus {
    border: none;
    outline: none;
}

.update--selected--transactions {
    background-color: #f03a44;
    color: #fff;
    height: 10vh;
    line-height: 10vh;
    font-size: 2.5em;
    padding-bottom: 5vh;
    grid-column: 1/-1;
}

.pagination--button--holder {
    max-width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.pagination--input {
    align-content: center;
    text-align: center;
    border: none;
    height: 100%;
    width: 100%;
    font-size: 30px;
    /* force input and 'of page count' to display in same cell */
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start: 1;
    grid-row-end: 1;
}

.pagination--total--pages {
    height: 100%;
    width: 100%;
    font-size: 13px;
    text-align: center;
    /* force input and 'of page count' to display in same cell */
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start: 1;
    grid-row-end: 1;
}

.pagination--total--pages--content {
    bottom: 0;
}

.arrow {
    border: solid black;
    border-width: 0 5px 5px 0;
    display: inline-block;
    padding: 3px;
}

.right-arrow {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
}

.left-arrow {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
}

.pulse--anim {
    background: #f03a44; /*#F03A44*/
    box-shadow: 0 0 0 0 #f03a44;
    transform: scale(1);
    animation: pulse 2s infinite;
}

@keyframes pulse {
    0% {
        transform: scale(1);
        box-shadow: 0 0 0 0 #f03a44;
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }

    100% {
        transform: scale(1);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
}
</style>
