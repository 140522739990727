<template>
    <div>
        <div class="product-view" @click="toggleProductEditModal">
            <div class="title">
                <span class="span-all-cols">Product Name</span>
            </div>

            <div class="span-all-cols">{{ availableCheck }}</div>

            <div>Price:</div>
            <div class="bold-text">
                {{ product.price ? product.price.current.value : "0.00" }}
            </div>

            <div>SKU:</div>
            <div>{{ product.SKU ? product.SKU.current.value : "000000" }}</div>

            <!-- <div class="group-header">Availibilty</div>
        <div>To</div>
        <div>From</div>

        <div class="bold-text">11/22/2020</div>
        <div class="bold-text">12/23/2021</div>

        <div>Units Sold:</div>
        <div>11223344</div>

        <div>SKU:</div>
        <div>11223344</div>

        <div>Purchase Limit:</div>
        <div>11223344</div>

        <div>Total Product:</div>
        <div>11223344</div>

        <div>Units Available:</div>
        <div>11223344</div>

        <div>Units In Transit:</div>
        <div>11223344</div>

        <div>Units On Hold:</div>
        <div>11223344</div>

        <div>Units Special:</div>
        <div>11223344</div> -->
        </div>
        <div v-if="productEditModalOpen">
            <ProductEditModal :product="product" />
        </div>
    </div>
</template>
<script>
import ProductEditModal from "./ProductEditModal.vue";
import store from ".././store";
// import 'vue-awesome/icons';
// import Icon from 'vue-awesome/components/Icon';
// import 'vue-awesome/icons/flag'  // for importing specific icons

export default {
    name: "ProductView",
    components: {
        ProductEditModal,
        // 'v-icon': Icon,
    },
    props: ["product"],
    data() {
        return {
            productEditModalOpen: false,
            showAdditionalDetails: false,
        };
    },
    methods: {
        toggleProductEditModal() {
            this.productEditModalOpen
                ? (this.productEditModalOpen = false)
                : (this.productEditModalOpen = true);
        },
        closeProductEditModal() {
            this.productEditModalOpen = false;
        },
        validateDetails(fieldToUpdate) {
            // REGEX here
            // front end validation here
        },
        // different method for product description updates?
        async updateProductField(fieldToUpdate, value) {
            this.validateDetails(value);
            let res;
            try {
                // TDOD - call fetch-services
                result = await fetch(
                    "https://some-gateway.execute-api.us-east-1.amazonaws.com/dev?accessCookie=" +
                        accessCookie +
                        "&username=" +
                        name +
                        "&field=" +
                        field +
                        "&value=" +
                        updatedValue
                ).then(async function (response) {
                    // savingIcon.style.display = "none";
                    if (response.ok) {
                        return response.json();
                    }
                    return "error";
                });
            } catch (err) {
                alert("error adding new product");
                console.error(err);
            }
        },
    },
    computed: {
        availableCheck() {
            return this.product.available.current ? "Available" : "Not on Sale";
        },
    },
};
</script>

<style scoped>
.product-view {
    display: grid;
    grid-row-gap: 3px;
    grid-template-columns: repeat(2, 1fr);
    min-height: 200px;
}

.title {
    font-weight: 900;
    text-align: center;
    align-items: center;
    background-color: lightblue;
}

.span-all-cols {
    grid-column: 1/-1;
}

.title-text {
    grid-column: 1 / 3;
}

.bold-text {
    font-weight: 900;
}

.group-header {
    grid-column: 1 / 3;
}

.card-img-top {
    max-width: 100%;
    max-height: 100%;
}
.close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}
.close:hover,
.close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

.addntl-product-details {
    transition: transform 0.5s;
    /* width: 0; */
    /* height: 100%; */
    /* min-width: 100px; */
    position: fixed;
    background-color: rgba(235, 225, 225, 0.667);
    transition: all 0.2s ease-in-out;
}
.addntl-product-details:hover {
    opacity: 0.9;
    background-color: whitesmoke;
    transform: scale(1);
}

.edit-btn {
    width: 100%;
    line-height: 2em;
    text-align: center;
    vertical-align: middle;
    font-size: 2em;
}

.social-card-header {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    /* height: 96px; */
}
</style>
