<template>
  <div class="transaction--view--grouping">
    <div class="transaction--view--button">{{transaction.transactionId}}</div>
    <div v-on:click="toggle('details')" v-bind:class="{activeMenu: detailsOpened}" class="transaction--view--button">Details</div>
    <div v-on:click="toggle('history')" v-bind:class="{activeMenu: historyOpened}" class="transaction--view--button">History</div>
    <div class="details--detail--menu" v-if="detailsOpened"></div>
    <div class="history--detail--menu" v-if="historyOpened"></div>
  </div>
</template>

<script>
export default {
  name: 'TransactionView',
  props: ["transaction"],
  data() {
    return {
      historyOpened: false,
      detailsOpened: false,
    };
  },
  methods: {
    toggle (type) {
      console.log('toggled');
      switch (type) {
        case 'details':
          this.detailsOpened = this.detailsOpened ? false : true;
          break;
        case 'history':
          this.historyOpened = this.historyOpened ? false : true;
          break;
        default:
          console.error('This should not happen!');
          break;
      }
    },
  },
};
</script>

<style scoped>
    .modal {
        position: fixed;
        z-index: 1;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        overflow: auto;
        background-color: rgb(0,0,0);
        background-color: rgba(0,0,0,0.4);
    }

    .modal-content {
        background-color: #fefefe;
        /* margin: 15% auto; */
        /* padding: 20px; */
        border: 1px solid #888;
        width: 80%;
    }

    .transaction--view--grouping {
      display: grid;
      grid-template-columns: 2fr 1fr 1fr;
      text-align: center;
      align-items: center;
      align-content: center;
      /* height: 6vh; */
      padding: 0px;
      margin: 0px;
    }

    .transaction--view--button {
      background-color: #fefefe;
      border: 1px solid #888;
      height: 100%;
    }

    .transaction--view--button--selected {
      background-color: lightsalmon;
    }

    .activeMenu {
      background-color: lightsalmon;
    }

    .details--detail--menu {

    }

    .history--detail--menu {

    }

    .close {
        color: #aaa;
        float: right;
        font-size: 28px;
        font-weight: bold;
    }

    .close:hover,
    .close:focus {
        color: black;
        text-decoration: none;
        cursor: pointer;
    }
</style>
