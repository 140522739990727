// import productList from "../local/products.json";
// import loginAccount from "../local/localLogin.json";
// import transactionList from "../local/transactions.json";
import localEvent from "../local/event.json";

// import login from '../../lambda/login';
import precheck_account_services from '../../lambda/precheck-account-services';
import { decycle, readSpecifiedCookie } from './helper-functions';
// import qr_code_services from '../../lambda/qr-code-services';
// import product_services from '../../lambda/product-services';
import { store } from '../store';

const funcMapping = {
    "precheck_account_services": precheck_account_services,
    // "qr_code_services": qr_code_services,
    "product_services": null,
    "login": null
}

const trickleCheck = {
    "product_services": true
}

export function generateId() {
    return Math.random().toString(36).substr(2, 15);
}

/* ### LOCAL DEVELOPMENT FUNCTIONALITY ### */
async function localCall(event, func, headersToUse, requestOpts, request) {
    let result;
    let funcToUse = null;

    headersToUse.forEach(header => {
        // capitilize first letter in header
        header = header.charAt(0).toUpperCase() + header.slice(1);
        event.params.header[header] = {};
        event.params.header[header] = requestOpts.headers.get(header);
    });

    if (requestOpts.body) {
        event.body = requestOpts.body;
    }

    funcToUse = funcMapping[func];
    if (!funcToUse) {
        return;
    }
    try {
        result = await funcToUse.handler(event);
    } catch (err) {
        console.error('local error detected');
        console.error(err);
    }
    console.log('local function call result', result);
    return result;
}

/**
 * 
 * @param {*} url String
 * @param {*} headersToAppend 
 * @param {*} request 
 * @param {Object} opts - kvp format
 * @returns 
 */
export async function fetchRequest(url, headersToAppend, request, type) {

    // let headers = new Headers();
    let headers = {};
    let responseObj;

    // pre append url usual BS if not included already (should be the norm moving forward)
    // url = !url.includes('https://sounal.com/rest/') ? 'https://sounal.com/rest/' + url : url;

    // user authorization to access backend
    if (readSpecifiedCookie("idToken")) {
        // headers.append("Authorization", "Bearer " + store.state.idToken);
        // headers["Authorization"] = "Bearer " + store.state.idToken;
        headers["Authorization"] = "Bearer " + readSpecifiedCookie("idToken");
    }

    // console.log('additional headers', headers);
    // console.log('additional headers to request with', headersToAppend);

    Object.keys(headersToAppend).forEach(header => {
        headers[header] = headersToAppend[header];
    })

    // set request id if provided to handle component level requests and tracking
    if (request.id) {
        headers["X-Sounal-Request-Id"] = request.id;
    }

    let requestOptions = {
        // method: type ? type : 'GET',
        // NOTE - local check b/c local Docker lambda fails if body is empty for both GET and POST, like wtf?
        method: location.hostname === "127.0.0.1" ? "POST" : (type ? type : 'GET'),
        headers: headers,
        redirect: "follow",
        "Content-Type": "application/json",
        "Accept-Charset": "utf-8"
    };
    console.log('desired request to send', request);
    console.log('request options', requestOptions);
    console.log('host', location.hostname);

    // if (location.hostname === "localhost") {
    //     console.log('is local, set some local development headers...');
    //     requestOptions.method = "POST"; // local Dockerized lambda functions require POST requests
    //     // with at-least an empty body
    //     requestOptions.body = !requestOptions.body ? requestOptions.body : {};

    //     // requestOptions = {
    //     //     method: 'POST', // *GET, POST, PUT, DELETE, etc.
    //     //     // mode: 'cors', // no-cors, *cors, same-origin
    //     //     cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    //     //     // credentials: 'same-origin', // include, *same-origin, omit
    //     //     headers: {
    //     //         "Content-Type": "application/json",
    //     //         "Access-Control-Allow-Methods": "*",
    //     //         "X-Requested-With": "*",
    //     //         "Access-Control-Allow-Origin": "*", // Required for CORS support to work
    //     //         "Access-Control-Allow-Credentials": true
    //     //     },
    //     //     redirect: 'follow', // manual, *follow, error
    //     //     referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    //     //     // body: JSON.stringify(data) // body data type must match "Content-Type" header
    //     //     // body: JSON.stringify({ "message": "Hello World!" })
    //     // }
    // }

    // var responseObj2 = await fetch(url, requestOptions)
    //     .then(function (response) {
    //         if (response.ok) {
    //             console.log('working...');
    //             return response.json();
    //         }
    //         return response;
    //     });
    // console.log('here...', responseObj2);

    // maybe handle certain other cases, such as batchGets where we need IDs?
    // if (request.payload && type === 'POST') {
    //     requestOptions.body = JSON.stringify(request.payload);
    // }

    // let target = (url.split('?')[0]).split('/rest/')[1];

    // NOTE adding payload to all requests - validate if can be cleaned up in a better way
    // requestOptions.body = JSON.stringify(request.payload);


    // true if want to run purely locally - should now be deprected
    let flag = false;
    if (flag) {

        // if (!url.includes('localhost') && (location.hostname === "localhost" || location.hostname === "127.0.0.1")) {
        responseObj = {
            statusCode: 200,
            body: {}
        }

        // locally setting mutliple headers
        let key;
        for (key of requestOptions.headers.keys()) {
            requestOptions.headers[key] = {};
            requestOptions.headers[key] = requestOptions.headers.get(key);
        }

        // locally setting querystring parameters
        let QSPString = url.split('?')[1];
        if (QSPString) {
            let QSPs = QSPString.split('&');
            QSPs.forEach(kvp => {
                requestOptions.queryStringParameters[kvp.split('=')[0]] = {};
                requestOptions.queryStringParameters[kvp.split('=')[0]] = kvp.split('=')[1];
            });
        }

        let event = {
            params: {
                // required to match syntax used with nodejs lambda functions locally
                header: requestOptions.headers,
                queryStringParamters: null,
            },
        };
        let localHeaders = [];
        // let target = (url.split('?')[0]).split('/rest/')[1];
        // console.log('target item', target);
        responseObj = await localCall(event, null, localHeaders, requestOptions); // used to be target as second param
    } else {

        if (location.hostname === "localhost") {
            console.log('is local, set some local development headers...');
            requestOptions.method = "POST"; // local Dockerized lambda functions require POST requests

            // let fullevent = localEvent;
            // console.log('full event setup', fullevent);
            // let e2 = Object.assign(localEvent, requestOptions);
            // console.log('e2', requestOptions.body);

            // merge headers first
            // let mergedHeaders = { ...requestOptions.headers, ...localEvent.headers };

            // DANGER - only use these headers for local development
            let mergedHeaders = localEvent.headers;
            mergedHeaders["Access-Control-Allow-Methods"] = "*";
            mergedHeaders["X-Requested-With"] = "*";
            mergedHeaders["Access-Control-Allow-Origin"] = "*";
            mergedHeaders["Access-Control-Allow-Credentials"] = true;

            // console.log('headers', requestOptions);
            // stupid but effective way of adding remaining headers
            Object.keys(requestOptions.headers).forEach(header => {
                mergedHeaders[header] = requestOptions.headers[header];
            });

            let mainRequest = { ...localEvent, ...requestOptions };
            let tmpBody = requestOptions.body ? requestOptions.body : {};

            let req = {};
            req = mainRequest
            req.headers = mergedHeaders;
            req.body = tmpBody
            console.log('testing...', mergedHeaders);
            console.log('testing2...', req.body);

            // in order to mock sqs locally, all incoming request bodies will be
            // wrapped in a "Records" object ie:
            /*
            {
                Records: {
                    messageId: "",
                    body: ""
                    ...
                }    

            } 
            */

            // req.body.Requests = [];
            // req.body.Requests = tmpBody;


            requestOptions.body = JSON.stringify(decycle(req));
            // requestOptions.body.body = tmpBody ? tmpBody : "{}";
            console.log('updated body, check headers for auth', requestOptions.body);
            // requestOptions.Records = [requestOptions];
            // console.log('records', requestOptions.Records);

            // let jsoned = JSON.stringify(decycle(requestOptions));   // body, the good ol switchero
            // let records = {
            //     'Records': [
            //         jsoned
            //     ],
            // }
            // requestOptions.body = "{}"; // init
            // requestOptions.body = JSON.stringify(decycle(records))   // ehh, lambda is capable, just direct hit lambda functions
            // requestOptions.body = requestOptions
            // requestOptions.Records = [];

            // requestOptions.body = jsoned;
            // requestOptions.Records = jsoned;
            // requestOptions.body = JSON.stringify(requestOptions.body);

            // now set for local request b/c that needs some crap
            // requestOptions.method = "POST"

            // NOW, in oder to minic SQS, toss everything in "Records" array if needed
            // if request is coming from front-end, should alweays have an SQS in front of it
            // requestOptions.Records = [];
            // let singleRecordsItem = requestOptions;
            // requestOptions = singleRecordsItem;
            // requestOptions.Records.push("some string");  // requestOptions

        }

        try {
            console.log('attempting call...', url);
            console.log('options', requestOptions);

            responseObj = await fetch(url, requestOptions)
                .then(function (response) {
                    if (response.ok) {
                        return response.json();
                    }
                    return false;
                });
        } catch (err) {
            console.error('error', err);
        }
    }

    console.log('response', responseObj);
    // send requestIds out to frontend components to handle loaders
    if (responseObj.body.requestId) {
        let msg;

        msg = responseObj.statusCode !== 200 ? responseObj.message : 'good';
        if (responseObj.body.requestId && store.getters.getRequestObjectbyId(responseObj, body.requestId)) {
            store.getters.getRequestObjectbyId(responseObj, body.requestId).msg = msg
            store.dispatch("REMOVE_REQUEST_ID", { id: responseObj.body.requestId, msg: msg });
        }
    }

    console.log('request response3', responseObj)
    console.log('request response4', JSON.parse(responseObj.body.data))

    return responseObj.body.data ? JSON.parse(responseObj.body.data) : null;
}