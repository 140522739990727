<template>
    <div class="modal">
        <!-- style="display: block" -->
        <div class="modal-content modal-input">
            <div @click="closeModal" class="close-div">
                <span class="close">&times;</span>
            </div>
            <div class="modal-title form-element title-grid">
                <div class="grid-prod-img-section">
                    <img src="//placeimg.com/280/180/tech" alt="" />
                </div>
                <!-- TODO - get defaults in order -->
                <div class="grid-title-section">Product Edit: {{ name }}</div>
                <div class="grid-prod-avail">
                    <v-icon
                        class="grid-scale-img"
                        name="check"
                        style="color: green"
                    ></v-icon>
                </div>
            </div>

            <div class="modal-title form-element title-grid">
                <div class="grid-prod-img-section">
                    <input id="src" type="file" />
                </div>
                <div class="">Product Edit: {{ name }}</div>
                <div v-if="displayErrorMsg" class="error-msg"></div>
            </div>

            <div class="main--content"></div>

            <!--
                    <div class="row modal-element">
                        <label for="am-company-display-name" class="col-xs-8 form-element">Company Display Name</label>
                        <div id="am-company-display-name-reset-field" class="col-xs-2"></div>
                        <input id="am-company-display-name" type="text" class="col-xs-10 form-element modal-textfield" pattern="[a-zA-Z0-9-]+" placeholder="Company Display Name" disabled>
                        <div id="am-company-display-name-id" class="toggle-edit col-xs-2 fa fa-lock fa-lg"></div>
                        <div class="col-xs-5"></div><div id="am-company-display-name-saving-icon" class="col-xs-2 saving-icon"></div><div id="am-company-display-name-success-text" class="form-success-text col-xs-5"></div>
                    </div>
                    @click="addToCount(item.contactID)"
                     -->

            <!-- <div class="grid-wrapper-labeler">
                        <div class="grid-item">Name</div>
                        <div class="grid-item"><v-icon class="grid-scale-img" name="check" style="color: green" size="4x"></v-icon></div>
                    </div> -->
            <div class="grid-wrapper">
                <div class="grid-item">
                    <v-icon class="grid-scale-img" name="sync"></v-icon>
                </div>
                <input
                    v-model="name"
                    class="form-control modal-textfield"
                    type="text"
                />
                <div class="toggle-edit fa fa-lock fa-lg"></div>
            </div>

            <div class="divider"></div>

            <div class="grid-wrapper-labeler">
                <div class="grid-item">Price (USD)</div>
                <div class="grid-item">
                    <v-icon
                        class="grid-scale-img"
                        name="check"
                        style="color: green"
                        size="4x"
                    ></v-icon>
                </div>
            </div>
            <div class="grid-wrapper">
                <div class="grid-item">Reset</div>
                <input
                    v-model="price"
                    class="form-control modal-textfield"
                    type="text"
                />
                <div class="toggle-edit fa fa-lock fa-lg"></div>
            </div>

            <div class="divider"></div>

            <div class="grid-wrapper-labeler">
                <div class="grid-item">Description</div>
                <div class="grid-item">
                    <v-icon
                        class="grid-scale-img"
                        name="check"
                        style="color: green"
                        size="4x"
                    ></v-icon>
                </div>
            </div>
            <div class="grid-wrapper">
                <div class="grid-item">Reset</div>
                <input
                    v-model="description"
                    class="form-control modal-textfield"
                    type="text"
                />
                <div class="toggle-edit fa fa-lock fa-lg"></div>
            </div>

            <div class="divider"></div>

            <div class="grid-wrapper-labeler">
                <div class="grid-item">SKU</div>
                <div class="grid-item">
                    <v-icon
                        class="grid-scale-img"
                        name="check"
                        style="color: green"
                        size="4x"
                    ></v-icon>
                </div>
            </div>
            <div class="grid-wrapper">
                <div class="grid-item">Reset</div>
                <input
                    v-model="sku"
                    class="form-control modal-textfield"
                    type="text"
                />
                <div class="toggle-edit fa fa-lock fa-lg"></div>
            </div>

            <div class="divider"></div>

            <div v-if="showLoadingIcon" class="loading">
                <i class="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
                <span class="sr-only">Loading...</span>
            </div>
            <div class="clear-div clear-modal">clear changes</div>
            <div class="clear-div clear-modal">download QR</div>
        </div>
    </div>
</template>

<script>
import { useStore } from "vuex";

// import "vue-awesome/icons";
// import Icon from 'vue-awesome/components/Icon';
// import 'vue-awesome/icons/flag'  // for importing specific icons

export default {
    name: "ProductEditModal",
    setup() {
        const store = useStore();
    },
    props: {
        details: Object,
        product: Object,
    },
    data() {
        return {
            displayErrorMsg: false, // set timer for display?
            opened: false,
            name: this.product.name,
            price: this.product.price.current.value,
            description: this.product.description.current.value,
            sku: this.product.sku.current.value,
            showLoadingIcon: false,
            isActivePriceInput: false,
            /*
            {"vendorIcon":{"S":"Icon1.png"},
            "QR":{"S":"5a498806-b050-4911-8a4b-9dac6f0f28a6"},
            "available":{"BOOL":false},
            "companyId":{"S":"746b0137-619a-4081-9651-b6f8c35de63d"}, // TODO - don't pass in
            "totalProd":{"S":"not set"},
            "fullQr":{"S":"https://hqvt0e6ldg.execute-api.us-east-1.amazonaws.com/dev?QR=5a498806-b050-4911-8a4b-9dac6f0f28a6"},
            "endAvail":{"S":"not set"},
            "salePrice":{"S":"not set"},
            "prodDesc":{"S":"true"},
            "startAvail":{"S":"not set"},
            "endSale":{"S":"not set"},
            "startSale":{"S":"not set"},
            "productImage1URL":{"S":"sounal-img-default.png"},
            "timeAdded":{"S":"2019-10-03"},"price":{"S":"true"},
            "purchaseLimit":{"S":"1"},
            "productId":{"S":"283e4822-75f1-4850-9b38-b4ee2b091039"}, // TODO - don't pass in
            "sku":{"S":"not set"},"productName":{"S":"true"}}
            */
        };
    },
    watch: {
        name: function () {
            // this.productName = this.productName.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '');
            this.name = this.name.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, "");
        },
        sku: function () {
            // this.productSku = this.productSku.replace(/[&\/\\#, +()$~%.'":*?<>{}]/g, '');
            this.sku = this.sku.replace(/[&\/\\#, +()$~%.'":*?<>{}]/g, "");
        },
        description: function () {
            // this.productDescription = this.productDescription.replace(/[&\/\\#()$~%'":*<>{}]/g, '');
            this.description = this.description.replace(
                /[&\/\\#()$~%'":*<>{}]/g,
                ""
            );
        },
    },
    mounted() {
        console.log("current displayed product", JSON.stringify(this.product));
    },
    methods: {
        closeModal() {
            this.$parent.closeProductEditModal();
        },
        stripTheGarbage(e) {
            if ((e.keyCode < 48 && e.keyCode !== 46) || e.keyCode > 59) {
                e.preventDefault();
            }
        },
        formatDollars() {
            if (this.productPrice != "") {
                let num = this.productPrice;

                num = Number(num);
                let countDecimals = function (value) {
                    if (Math.floor(value) === value) return 0;
                    return value.toString().split(".")[1].length || 0;
                };
                let decimal = countDecimals(num);
                if (decimal < 2) {
                    num = num.toFixed(2);
                }
                if (decimal > 2) {
                    num = num.toFixed(decimal);
                }
                if (parseInt(num) < 1) {
                    num = "." + String(num).split(".")[1];
                }
                this.productPrice = num;
            }
        },
        clearFields() {
            // not used, probably use in quick add only
            this.productName = "";
            this.productDescription = "";
            this.productPrice = 0.0;
            this.name = "";
            this.price = 0.0;
            this.description = "";
            this.sku = "";
        },
        validatePrice() {
            if (this.isInputActive) {
                return this.value.toString();
            } else {
                return (
                    "$ " +
                    this.value
                        .toFixed(2)
                        .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")
                );
            }
        },
        async editProduct(fieldToUpdate, value) {
            let result;
            this.showLoadingIcon = true;

            // validate here
            if (this.product.available.S) {
                return false;
            }

            /*
        timeAdded (not editable)
        idToken (not editable)
        username    (not editable)
        productName:
        price:
        description:
        endAvail:
        startAvail:
        sku:
        totalProd:
        purchaseLimit:
        salePrice:
        startSale:
        endSale
        "unitsAvailable": {S: '0'},
        "unitsInTransit": {S: '0'}, // units in transit to purchaser
        "unitsOnHold": {S: '0'},    // units on hold specified by vendor
        "unitsSold": {S: '0'},      // units completely moved with complete transaction and delivery
        "unitsWithInSpecialInstruction": {S: '0'},  // units awaiting special instructions
        "unitsReturned": {S: '0'},  // units the vendor has specified with a 'returned' status
        */

            // check if product actively being sold, cannot request

            if (fieldToUpdate === "description") {
            }

            try {
                // productUpdate Api Gateway
                // result = await fetch('https://d3t6wcrxxl.execute-api.us-east-1.amazonaws.com/dev?idToken=' + store.idToken + "&username=" + store.currentUser + "&productName=" + this.productName + "&price=" + this.productPrice + "&description=" + this.productDescription)
                result = await fetch(
                    "https://d3t6wcrxxl.execute-api.us-east-1.amazonaws.com/dev?idToken=" +
                        store.idToken +
                        "&username=" +
                        store.currentUser +
                        "&fieldToUpdate=" +
                        fieldToUpdate +
                        "&value=" +
                        value
                ).then(function (response) {
                    if (response.ok) {
                        return response;
                    }
                    return false;
                });
            } catch (err) {
                console.error("adding new product error detected", err);
            } finally {
                this.showLoadingIcon = false;
            }
            console.log("product edit response", result);
            if (!result) {
                alert("error adding new product");
                return false;
            }
            this.closeModal();
            this.$parent.displaySuccessMsg();
        },
    },
};
</script>

<style scoped>
.main--content {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-content: center;
    align-items: center;
}
.data--row {
    align-items: center;
    font-size: 1.5em;
}
.data--row--textfield {
    font-size: 1.5em;
}
.grid-wrapper {
    display: grid;
    grid-template-columns: 1fr 3fr 1fr;
    align-items: center;
}
.grid-wrapper-labeler {
    display: grid;
    grid-template-columns: 3fr 1fr;
    align-items: center;
}
.grid-item {
    /* vertical-align: center; */
    /* min-height: 35px; */
    min-height: 3em;
    line-height: 3em;
}
.grid-scale-img {
    min-height: 3em;
    min-width: 30px;
    max-width: 100%;
}
.title-grid {
    display: grid;
    grid-template-columns: 1fr 3fr 1fr;
    align-items: center;
}
.grid-date-section {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr 2fr; /* 'start' ## input ## to 'end' */
    align-items: center;
}
.grid-prod-img-section {
    font-size: 0.5em;
}
.row-group {
    background-color: aquamarine;
}
.modal {
    position: fixed;
    z-index: 5;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    /* background-color: rgb(0,0,0); */
    background-color: rgba(0, 0, 0, 0.4);
    /* background-color: blue; */
}

.modal-content {
    /* background-color: #fefefe; */
    /* background-color: red; */
    background-color: #fefefe;
    margin: 15% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
}

.modal-textfield {
    /*border: solid;*/
    /* width: 80%; */
    background-color: lightgray;
    color: black;
    height: 35px;
    border-left: solid;
    border-top: solid;
    border-bottom: solid;
    border-color: lightgray;
    border-width: 1px;
    border-right-color: gray;
}

.toggle-edit-product {
    height: 35px;
    border: solid;
    color: white;
    border-color: #3b175c;
    border-width: 1px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    text-align: center;
    line-height: 35px;
    background-color: #3b175c;
}

.toggle-edit:hover {
    cursor: pointer;
    background-color: goldenrod;
    border-color: goldenrod;
    animation: shake 0.5s;
    animation-iteration-count: infinite;
}

.toggle-edit-product:hover {
    cursor: pointer;
    background-color: goldenrod;
    border-color: goldenrod;
    animation: shake 0.5s;
    animation-iteration-count: infinite;
}

.toggle-edit-perm-lock {
    background-color: #888;
}

.product-displayer .make-available-btn {
    min-height: 50px;
    min-width: 50px;
    width: 100%;
    height: 100%;
    background-color: lightgray;
    color: lime;
}
.make-available-btn:hover {
    cursor: pointer;
}
.availibility-text {
    text-align: center;
}

.close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}
</style>
