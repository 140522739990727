'use strict';

/**
 * permissions:
 * cognito: adminGetUser read
 * dynamo: query read
 * */


// const AmazonCognitoIdentity = require('amazon-cognito-identity-js');
// const request = require('request');
// const jwkToPem = require('jwk-to-pem');
// const jwt = require('jsonwebtoken');
// global.fetch = require('node-fetch');
const AWS = require('aws-sdk');
// BAD BAD BAD - THIS IS A NO NO, set dynamically
AWS.config.update({ accessKeyId: 'AKIARNQC4FHLD3SPTPE6', secretAccessKey: '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' });

let cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider(
    {
        region: 'us-east-1',
        apiVersion: '2016-04-18'
    }
);

/* TODO - set env vars */
const poolId = 'us-east-1_djO2ADBSk';
let poolData = {
    UserPoolId: 'us-east-1_djO2ADBSk',     // TODO - make env vars
    ClientId: '57d46rd1rdg9acb5cuhqh6lpkt' // TODO - make env vars
};
// let userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);

// let DDB = new AWS.DynamoDB({
//     region: 'us-east-1'
// });

/**
 * Checks if email exists
 * @returns true if user exists, false otherwise
*/
async function asyncCheckEmail(email) {
    let result = false;
    let getUserParams = {
        UserPoolId: poolId,
        Username: email
    };
    console.log('incoming email attempt', email);
    return new Promise(function (resolve, reject) {
        cognitoidentityserviceprovider.adminGetUser(getUserParams, function (err, data) {
            if (err) {
                console.error('user details', err);
                resolve(false);
            } else {
                console.log('', data);
                console.log('user does not exist in userpool', err);
                result = true;
                resolve(true);
            }
        });
    })
}

exports.handler = async (event) => {
    console.log('incoming', event.params);
    let request, data, details;
    let result = false;
    let response = {
        statusCode: 400,
        body: ""
    };

    try {
        request = event.params.header['RequestType'];
        data = event.params.header['Payload'];
        details = Buffer.from(data, 'base64').toString();
    } catch (err) {
        return {
            statusCode: 401,
            body: "invalid login credentials"
        }
    }

    switch (request) {
        case 'validateEmail':
            result = await asyncCheckEmail(details);
            if (result) {
                response.statusCode = 200;
                response.body = result ? "email already exists" : "email does not exist";

            } else {
                response.statusCode = 401;
                response.body = result ? "email already exists" : "email does not exist";
            }
            console.log('request response', result);
            break;
        default:
            break;
    }
    return response;
};

