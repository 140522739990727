/* eslint-disable */

import LoginModal from './components/account/LoginModal.vue';
import CreateAccountManager from './components/account/CreateAccountManager.vue';
import Dashboard from './components/Dashboard.vue';
import EditProduct from './components/ProductEditModal.vue';
import NotFoundView from './components/NotFoundView.vue';
import TransactionsView from './components/TransactionsView.vue';
import TransactionsUpdateModal from './components/TransactionsUpdateModal.vue';
import TransactionView from './components/TransactionView.vue';
import ProductList from './components/products/ProductList.vue';
import QRCodeDisplayer from './components/products/QRCodeDisplayer.vue';
import CodeRow from './components/products/CodeRow.vue';
import AccountSettingsEdit from './components/AccountEditView.vue';
import FullPageModal from './components/Shared/FullPageModal.vue';
import Modal from './components/Shared/Modal.vue';
import SettingsPage from './components/pages/SettingsDashboard.vue';


/* additional functions */
// import { readSpecifiedCookie, getCurrentUser, logout } from "../auth/auth";

// import store from './store.js';
// import product-services from './services-frontend/product-services';
// import transactionServices from './services-frontend/transactionServices';

const routes = [
  {
    path: '/', component: Dashboard, auth: true,
    children: [
      {
        name: 'AccountSettings',
        path: '/settings/account',
        component: AccountSettingsEdit,
        props: true
      },
      {
        name: 'CompanySettings',
        path: '/settings/company',
        component: Modal,
        props: true
      },
      {
        name: 'Settings',
        path: '/settings/',
        component: SettingsPage,
        props: true
      },
      {
        name: 'Transactions', path: '/transactions', component: TransactionsView,
        children: [
          { path: '/Transaction/:transactionId', component: TransactionView },
          { path: '/Transaction/:transactions', component: TransactionsUpdateModal }
        ]
      },
      {
        name: 'Products', path: '/products', component: ProductList,
        children: [
          {
            name: 'EditProduct', path: '/EditProduct', component: EditProduct,
            children: [
              // { path: 'Product/:id', component: EditProduct }
            ]
          },
          {
            name: 'Code',
            path: '/products/code/:id',
            component: Modal,
            props: true
          },
          {
            name: 'EditCode',
            path: '/products/:productId/code/edit/:id',
            component: Modal,
            props: true
          }
        ]
      },
    ],
  },
  // full-page modal handling
  {
    name: 'account', path: '/account', component: FullPageModal,
    children: [
      { name: 'Login', path: '/login', component: LoginModal },
      {
        name: 'CreateAccount',
        path: '/CreateAccount',
        component: CreateAccountManager,
      },
      // { name: 'CreateAccount', path: '/CreateAccount', component: LoginPage},
      // { name: 'ResetPassword', path: '/CreateAccount', component: LoginPage},
    ]
  },

  // { path: '/Dashboard', redirect: '/',},
  // { path: '*', component: NotFoundView }
  {
    path: "/:catchAll(.*)", // Unrecognized path automatically matches 404
    redirect: '/404',
  },
];

export default routes