<template>
  <div id="accountModal" class="modal">
    <div class="modal-content modal-input form-element">
        <v-icon v-on:click="toggleEditOptions" name="lock" size="8x" class="left-side-lock"></v-icon>
        <span id="closeAccountEditModal" class="close">&times;</span>
        <div class="modal-header">
            <h2>Account Details</h2>
        </div>

        <label for="qa-product-name" class="form-element">Product Name</label>
        <div class="grid-wrapper">
            <div class="grid-item"><v-icon class="grid-scale-img" name="sync"></v-icon></div>
            <input v-model="name" class="form-control modal-textfield" type="text">
            <div class="toggle-edit fa fa-lock fa-lg"></div>
        </div>

        <div class="row modal-element">
            <label for="cm-account-name" class="col-xs-8 form-element">Name</label>
            <div id="cm-account-name-reset-field" class="col-xs-2 reset-element fa fa-undo" style="display: none"></div>
            <input v-model="name" id="cm-account-name" type="text" class="col-xs-10 form-element modal-textfield" pattern="[a-zA-Z0-9-]+" placeholder="Name" disabled>
            <div id="cm-account-name-id" class="toggle-edit col-xs-2 fa fa-lock fa-lg"></div>
            <div class="col-xs-5"></div><div id="cm-account-name-saving-icon" class="col-xs-2 saving-icon fa fa-spin"></div><div id="cm-account-name-success-text" class="form-success-text col-xs-5"></div>
        </div>

        <br>

        <div class="row modal-element">
            <label for="cm-account-email" class="col-xs-8 form-element">Email</label>
            <div id="cm-account-email-reset-field" class="col-xs-2"></div>
            <input v-model="email" id="cm-account-email" type="text" class="col-xs-10 form-element modal-textfield" pattern="[a-zA-Z0-9-]+" placeholder="Email" disabled>
            <div id="cm-account-email-id" class="toggle-edit col-xs-2 fa fa-lock fa-lg" ></div>
            <div class="col-xs-5"></div><div id="cm-account-email-saving-icon" class="col-xs-2 saving-icon"></div><div id="cm-account-email-success-text" class="form-success-text col-xs-5"></div>
        </div>

        <br>

        <div class="row modal-element">
            <label for="cm-account-backup-email" class="col-xs-8 form-element">Backup Email</label>
            <div id="cm-account-backup-email-reset-field" class="col-xs-2"></div>
            <input v-model="backupEmail" id="cm-account-backup-email" type="text" class="col-xs-10 form-element modal-textfield" pattern="[a-zA-Z0-9-]+" placeholder="Backup Email" disabled>
            <div id="cm-account-backup-email-id" class="toggle-edit col-xs-2 fa fa-lock fa-lg"></div>
            <div class="col-xs-5"></div><div id="cm-account-backup-email-saving-icon" class="col-xs-2 saving-icon"></div><div id="cm-account-backup-email-success-text" class="form-success-text col-xs-5"></div>
        </div>

        <br>

        <div class="row modal-element">
            <label for="cm-account-phone" class="col-xs-8 form-element">Phone #</label>
            <div id="cm-account-phone-reset-field" class="col-xs-2" ></div>
            <input v-model="phone" id="cm-account-phone" type="text" class="col-xs-10 form-element modal-textfield" pattern="[a-zA-Z0-9-]+" placeholder="Phone #" disabled>
            <div id="cm-account-phone-id" class="toggle-edit col-xs-2 fa fa-lock fa-lg"></div>
            <div class="col-xs-5"></div><div id="cm-account-phone-saving-icon" class="col-xs-2 saving-icon"></div><div id="cm-account-phone-success-text" class="form-success-text col-xs-5"></div>
        </div>
    </div>

    <div v-if="showLoadingIcon" class="loading" >
        <i class="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
        <span class="sr-only">Loading...</span>
    </div>
    <!-- and disable all inputs when loading/saving -->

  </div>
</template>

<script>
export default {
  name: 'AccountEditModal',
  props: {
    details: Object,
  },
  data() {
    return {
      phone: '',
      email: '',
      backupEmail: '',
      phone: '',
      showLoadingIcon: false,
      opened: false,
      editEnabled: false
    };
  },
  methods: {
    toggleEditOptions() {
      // normally check for permission first
      if (this.editEnabled) {
        // save any new incoming data

        this.editEnabled = false;
      } else {
        // open textfields for editing

        this.editEnabled = true;
      }

    }
  },
};
</script>

<style scoped>

    .modal {
        position: fixed;
        z-index: 1;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        overflow: auto;
        background-color: rgb(0,0,0);
        background-color: rgba(0,0,0,0.4);
    }

    .modal-content {
        background-color: #fefefe;
        margin: 15% auto;
        padding: 20px;
        border: 1px solid #888;
        width: 80%;
    }

    .grid-wrapper {
        display: grid;
        grid-template-columns: 1fr 3fr 1fr;
        align-items: center;
    }
    .grid-wrapper-labeler {
        display: grid;
        grid-template-columns: 3fr 1fr;
        align-items: center;
    }
    .grid-item {
        /* vertical-align: center; */
        /* min-height: 35px; */
        min-height: 3em;
        line-height: 3em;
    }

    .toggle-edit{
      height: 35px;
      border: solid;
      color: white;
      border-color: #3b175c;
      border-width: 1px;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      text-align: center;
      line-height: 35px;
      background-color: #3b175c;
  }

  .toggle-edit:hover {
      cursor: pointer;
      background-color: goldenrod;
      border-color: goldenrod;
      animation: shake 0.5s;
      animation-iteration-count: infinite;
  }

     .modal-textfield {
        /*border: solid;*/
        background-color: lightgray;
        color: black;
        padding-top: 3px;
        padding-bottom: 3px;
        /* width: 60%; */
        height: 35px;
        border-left: solid;
        border-top: solid;
        border-bottom: solid;
        border-color: lightgray;
        border-width: 1px;
        border-right-color: gray;
  }

  .reset-element{
      height: 25px;
      color: white;
      text-align: center;
      line-height: 25px;
      border-top-right-radius: 5px;
      border-top-left-radius: 5px;
      background-color: gray;
  }

  .left-side-lock {
    float: left;
    padding-left: 10px;
  }
  .left-side-lock:hover,
  .left-side-lock:focus {
    color: red;
    cursor: pointer;
    /* background-color: goldenrod;
    border-color: goldenrod; */
    animation: shake 0.5s;
    animation-iteration-count: infinite;
  }

    .close {
        color: #aaa;
        float: right;
        font-size: 28px;
        font-weight: bold;
    }

    .close:hover,
    .close:focus {
        color: black;
        text-decoration: none;
        cursor: pointer;
    }
</style>
