import { fetchRequest, generateId } from "./fetch-services.js";
import { convertArrayToObject } from "./helper-functions.js";
import { RequestObject } from "../models/RequestObject";
import { store } from '../store'

/**
 * Gets all products for specified company
 * @param {*} request 
 * @param {*} onlyGetUpdated 
 * @returns 
 */
export async function getProducts(request, onlyGetUpdated = false) {

    // if IDs is empty, get all products
    let res,
        lastKey,
        opts = null,
        url = process.env.PRODUCT_SERVICES_URL;

    try {
        do {
            url += !lastKey ? "" : `?lastKey=${lastKey}`;
            // pass in extra desired headers vs setting entire request here
            let headersToAppend = { "X-Sounal-Request-Type": "get-products" };
            // if (onlyGetUpdated) {    // TODO - figure out
            //     headersToAppend["X-Sounal-Updated-Only"] = true;
            // }

            // TODO - house this entirely within the store.js file in order to use 
            // update requests or something like that objects for uniform requests across app!
            if (!request || !request.id) { // TODO - REMOVE
                request = {};
                request.id = '000';
                request.IDs = [];
            }
            // request = request
            res = await fetchRequest(url, headersToAppend, request, opts);
            console.log("response from products fetch", res);

            if (res && res.Items) {
                store.dispatch("SET_PRODUCTS", res.Items);
                lastKey = res.lastKey;
            } else {
                lastKey = null;
            }
        } while (lastKey);
    } catch (err) {
        console.error("something has gone awry", err);
    }
    return res;    // only need to return for request id
}

/**
 * Gets specified products for specified company based ojn passed in IDs
 * @param {*} request 
 * @param {*} onlyGetUpdated 
 * @returns 
 */
export async function getSpecifiedProducts(request) {

    let res,
        opts = null,
        url = process.env.PRODUCT_SERVICES_URL;

    try {
        let headersToAppend = { "X-Sounal-Request-Type": "get-specified-products" };

        // null checks
        if (!request || !request.id || !request.payload) {
            return;
        }
        res = await fetchRequest(url, headersToAppend, request, opts);
        console.log("response from batch products fetch", res);
    } catch (err) {
        console.error("something has gone awry in batchget", err);
    }

    if (request.payload.updateStore) {
        // parse out immediate response and add to store data

        // need to handle any UnprocessedKeys?
        if (res.Responses && res.Responses['Product']) {

            // respnse if batchget request
            if (res.Responses && res.Responses.Product) {
                store.dispatch("SET_PRODUCTS", res.Responses.Product);
            } else {
                // general response
                console.log('updating products...', res.Responses['Product']);
                store.dispatch("SET_PRODUCTS", res.Responses['Product']);
            }
        }
    }

    return res;    // only need to return for request id
}

/**
 * should handle creation of new products as well as updating products that already exist
 * @param {*} request 
 * @returns 
 */
export async function updateProduct(request) {
    console.log('updateProduct called');
    let headersToAppend = {
        "X-Sounal-Request-Type": "update-product",
        "X-Sounal-Product-Id": request.payload.id,
    };

    let res, url = process.env.PRODUCT_SERVICES_URL;
    try {
        res = await fetchRequest(url, headersToAppend, request, 'POST');
        console.log("response from products fetch", res);
    } catch (err) {
        console.error("something has gone awry", err);
        return false;
    }

    let req = new RequestObject({
        id: generateId(),
        requestType: "get-items",
        payload: {
            IDs: [request.payload.id],
            updateStore: true
        },
    });
    store.dispatch("REQUEST_UPDATE", req);

    return res;
}