export class BaseObject {
    // constructor() {
    //     this._type = 'Base'
    // }
    validate(value, type = 'string') {
        if (typeof value !== type) {
            throw `INVALID TYPE - ${value} is of type ${typeof value} and the expected tpye is ${type}`;
        } else {
            return value;
        }
    }
}