<template>
    <div id="quickAddModal" class="modal" style="display: block">
        <form
            id="quickAddForm"
            v-on:submit.prevent
            action=""
            class="form-element"
            method="post"
        >
            <div class="modal-content">
                <div></div>
                <div @click="closeModal" class="close-div">
                    <span id="closeQuickAddModal" class="close">&times;</span>
                </div>
                <div class="title-text">Product</div>

                <div for="qa-product-name" class="">Product Name:</div>
                <div class="text-field-sub-group">
                    <input
                        v-model="productName"
                        required=""
                        class="modal-textfield"
                        type="text"
                        placeholder="product name"
                    />
                    <v-icon
                        class="center-icon"
                        name="box"
                        scale="1.5"
                        style="color: black"
                    ></v-icon>
                </div>

                <label for="qa-product-name" class="">ID (optional)</label>
                <div class="text-field-sub-group">
                    <input
                        v-model="productDescription"
                        required=""
                        class="modal-textfield"
                        type="text"
                        placeholder="product description"
                    />
                    <v-icon
                        class="center-icon"
                        name="align-left"
                        scale="1.5"
                        style="color: black"
                    ></v-icon>
                </div>

                <!-- <label for="qa-product-name" class="">QR</label>
                <div class="text-field-sub-group">
                    <input v-model="productPrice" required="" class="modal-textfield" type="text" placeholder="$0.00">
                    <v-icon class="center-icon" name="dollar-sign" scale="1.5" style="color: black"></v-icon>
                </div> -->

                <label for="code--section" class="">Codes</label>
                <!-- dynamic codes generation -->
                <div class="code--section" v-for="code in codes" :key="code.id">
                    <div>URL</div>
                    <input
                        v-model="code.value"
                        required=""
                        class="modal-textfield"
                        type="text"
                        placeholder="URL"
                    />
                </div>

                <!-- <label for="qa-product-name" class="">SKU</label>
                <div class="text-field-sub-group">
                    <input v-model="productSku" required="" class="modal-textfield" type="text" placeholder="product SKU">
                    <v-icon class="center-icon" name="key" scale="1.5" style="color: black"></v-icon>
                </div> -->

                <!-- <label for="qa-product-name" class="">Price (USD)</label>
                <div class="text-field-sub-group">
                    <input v-model="productPrice" required="" class="modal-textfield" type="text" placeholder="$0.00">
                    <v-icon class="center-icon" name="dollar-sign" scale="1.5" style="color: black"></v-icon>
                </div> -->

                <div class="form-group form-element title-text">
                    <button
                        @click="addNewProduct"
                        type="submit"
                        class="btn btn-primary btn-block submit-button"
                    >
                        Create
                    </button>
                </div>

                <div class="form-group form-element title-text error-section">
                    <div v-if="errorMsg != ''">{{ errorMsg }}</div>
                </div>

                <div v-if="showLoadingIcon" class="loading title-text">
                    <i class="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
            <canvas id="canvas"></canvas>
        </form>
    </div>
</template>

<script>
// import "vue-awesome/icons";
import store from ".././store";
import Vue from "vue";
// import QrcodeVue from 'qrcode.vue'

export default {
    name: "Product",
    components: {},
    props: {
        product: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            opened: false,
            productName: "",
            productPrice: 0.0,
            productDescription: "",
            productSku: "",
            showLoadingIcon: false,
            isActivePriceInput: false,
            errorMsg: "",
            codes: [
                {
                    id: "1234",
                    url: "some-url.com",
                    tracking: {},
                },
            ],
            // codeObj = { id: id, value: url }
        };
    },
    watch: {
        productName: function () {
            this.productName = this.productName.replace(
                /[&\/\\#,+()$~%.'":*?<>{}]/g,
                ""
            );
        },
        productSku: function () {
            this.productSku = this.productSku.replace(
                /[&\/\\#, +()$~%.'":*?<>{}]/g,
                ""
            );
        },
        productDescription: function () {
            this.productDescription = this.productDescription.replace(
                /[&\/\\#()$~%'":*<>{}]/g,
                ""
            );
        },
    },
    mounted() {
        // console.log('qr code services', qr_code);
        // new qr_code(document.getElementById("qrcode"), "https://localhost:8080/#/login");
        // console.log('testing...', new QRCode(document.getElementById("qrcode"), "https://localhost:8080/#/login"));
        //   new QRCode(document.getElementById("qrcode"), "https://localhost:8080/#/login");
        // var canvas = document.getElementById('canvas');
        // const generateQR = async text => {
        //     try {
        //         await QRCode.toCanvas(canvas, text)
        //     } catch (err) {
        //         console.error(err)
        //     }
        // }
        // generateQR("https://localhost:8080/#/login");
        //     QRCode.toString('I am a pony!',{type:'terminal'}, function (err, url) {
        //     console.log('testing qr code lib in front end', url)
        // })
    },
    methods: {
        closeModal() {
            console.log("closing quick add modal");
            this.$parent.closeAnyOpenModal();
        },
        stripTheGarbage(e) {
            if ((e.keyCode < 48 && e.keyCode !== 46) || e.keyCode > 59) {
                e.preventDefault();
            }
        },
        formatDollars() {
            if (this.productPrice != "") {
                let num = this.productPrice;

                num = Number(num);
                let countDecimals = function (value) {
                    if (Math.floor(value) === value) return 0;
                    return value.toString().split(".")[1].length || 0;
                };
                let decimal = countDecimals(num);
                if (decimal < 2) {
                    num = num.toFixed(2);
                }
                if (decimal > 2) {
                    num = num.toFixed(decimal);
                }
                if (parseInt(num) < 1) {
                    num = "." + String(num).split(".")[1];
                }
                this.productPrice = num;
            }
        },
        clearFields() {
            this.productName = "";
            this.productDescription = "";
            this.productPrice = 0.0;
        },
        validatePrice() {
            if (this.isInputActive) {
                return this.value.toString();
            } else {
                return (
                    "$ " +
                    this.value
                        .toFixed(2)
                        .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")
                );
            }
        },

        async addNewProduct() {
            if (
                ![
                    this.productName,
                    this.productDescription,
                    this.productSku,
                ].every(Boolean)
            ) {
                this.errorMsg = "Some value is missing!";
                return false;
            } else {
                this.errorMsg = "";
            }

            let result;
            this.showLoadingIcon = true;

            // console.log(`https://hb3fbkhpul.execute-api.us-east-1.amazonaws.com/dev?idToken=' + ${idCookie} + "&username=" + ${usernameCookie} + "&productName=" + ${productName} + "&price=" + ${price} + "&description=" + ${description}`);
            try {
                // wait
                // productQuickAdd Api Gateway
                // /service/create_product/*
                // https://hb3fbkhpul.execute-api.us-east-1.amazonaws.com/dev?idToken=' + store.idToken + "&username=" + store.currentUser + "&productName=" + this.productName + "&price=" + this.productPrice + "&description=" + this.productDescription
                // result = await fetch('https://sounal/service/create_product?productName=' + this.productName + "&price=" + this.productPrice + "&description=" + this.productDescription)
                //     .then(function(response) {
                //         if(response.ok){
                //             return response;
                //         }
                //         return false;
                //     });
            } catch (err) {
                console.error("adding new product error detected", err);
                this.errorMsg = "An error occured";
            } finally {
                this.showLoadingIcon = false;
            }
            console.log("response from adding new product", result);
            if (!result) {
                this.errorMsg = "An error occured";
                return false;
            }
            this.errorMsg = "";

            const newProd = {
                name: this.productName,
                price: this.productPrice,
                description: this.productDescription,
            };
            this.$parent.addProduct();
            this.closeModal();
            this.$parent.displaySuccessMsg();
        },
    },
};
</script>

<style scoped>
.modal {
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);
}

.modal-title {
    font-size: 1.5em;
    color: black;
    text-align: center;
}

.modal-header {
    text-align: center;
}

.modal-content {
    background-color: #fefefe;
    border-radius: 9px;
    position: fixed;
    top: 10%;
    left: 5%;
    width: 90%;
    height: 80%;
    display: grid;
    grid-gap: 1em;
    grid-template-columns: 1fr 3fr;
    text-align: center;
    align-items: center;
    align-content: center;
    font-size: 1.5em;
    /* bring your own prefixes */
    /* transform: translate(-25%, -25%); */
}

.code--section {
    background-color: #fefefe;
    /* margin: 2px; */
    margin: 1% auto;
    padding: 10px;
    border: 1px solid #888;
    width: 90%;
    grid-column: 1 / -1;
    display: grid;
    grid-gap: 1em;
    grid-template-columns: 2fr 6fr 1fr;
    text-align: center;
    align-items: center;
    align-content: center;
    font-size: 1.5em;
    border-radius: 9px;
}

.text-field-sub-group {
    display: grid;
    grid-template-columns: 1fr 50px;
    text-align: center;
    align-items: center;
    align-content: center;
    /* padding-right: 50px; */
}

.center-icon {
    align-self: center;
    justify-self: center;
    padding-left: 5px;
}

.title-text {
    grid-column: 1 / 3;
}

.submit-button {
    font-size: 1.3em;
    cursor: pointer;
    border-radius: 18px;
    background-color: lightblue;
    border: solid 1px transparent;
    padding-left: 20px;
    padding-right: 20px;
    transition-property: background, border-radius;
    transition-duration: 0.2s, 1s;
    transition-timing-function: linear, ease-in;
    transition-delay: 0s, 1s;
}
.submit-button:hover {
    cursor: pointer;
    background: #dee602;
}

.error-section {
    background-color: lightcoral;
}

.modal-header {
    text-align: center;
}

.modal-font-plus {
    background-color: #0eacf9;
    border-radius: 50%;
    padding: 10px;
    overflow: visible;
}

.toggle-edit {
    height: 35px;
    border: solid;
    color: white;
    border-color: #3b175c;
    border-width: 1px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    text-align: center;
    line-height: 35px;
    background-color: #3b175c;
}

.toggle-edit:hover {
    cursor: pointer;
    background-color: goldenrod;
    border-color: goldenrod;
    animation: shake 0.5s;
    animation-iteration-count: infinite;
}

.modal-textfield {
    background-color: lightgray;
    text-indent: 20px;
    font-size: 1.1em;
    color: black;
    padding-top: 3px;
    padding-bottom: 3px;
    height: 100%;
    width: 100%;
    border-radius: 13px;
    border-left: solid;
    border-top: solid;
    border-bottom: solid;
    border-color: lightgray;
    /* border-width: 1px; */
}

.reset-element {
    height: 25px;
    color: white;
    text-align: center;
    line-height: 25px;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    background-color: gray;
}
.close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
    padding-right: 10px;
}
.close:hover,
.close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}
</style>
