import { BaseObject } from './BaseObject';

// TODO - validate and cleanup mappings
const updateType = {
    "update-code": "update-code",
    "delete-code": "delete-code",
    "update-item": "update-item",
    "delete-item": "delete-item",
    "get-item": "get-item",
    "get-items": "get-items",
    "delete-items": "delete-items",
    "get-products": "get-products",
    "get-specified-products": "get-specified-products"
}

/**
 * Update request objects used for sending requests to backend from
 * the store
 */
export class RequestObject extends BaseObject {
    constructor(document) {
        super()
        this._type = 'RequestObject'
        this.id = document.id;
        this.requestType = document.requestType;
        this.payload = document.payload;
    }
    set id(value) {
        // console.log('--', super.validate);
        try {
            this._id = super.validate(value, 'string');

        } catch (err) {
            console.log('err', err);
        }
        // this._id = Base.prototype.validate(value, 'string');
    }
    get id() {
        return this._id;
    }
    set requestType(value) {
        if (updateType[value]) {
            this._requestType = updateType[value];
        } else {
            throw 'Illegal Type';
        }
    }
    get requestType() {
        return this._requestType;
    }
    set payload(value) {
        this._payload = super.validate(value, 'object');
        // this._item = Base.prototype.validate(value, 'object');
    }
    get payload() {
        return this._payload;
    }
}
