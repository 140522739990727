import { fetchRequest } from '../services-frontend/fetch-services.js';
// import store from '../store';
// import { useStore } from 'vuex'
import { store } from '../store'

export async function getAllTransactions(params, total) {
    console.log("Querying Table");
    let data = await docClient.query(params).promise();

    if (data['Items'].length > 0) {
        store.state.transactions = [...store.state.transactions, ...data['Items']];
    }

    if (data.LastEvaluatedKey) {
        params.ExclusiveStartKey = data.LastEvaluatedKey;
        return await getAllTransactions(params, total);

    } else {
        return data;
    }
}

export async function getTransactionsWithinRange(start, end) {

    // console.log("Querying Table");
    // let data = await docClient.query(params).promise();

    // //
    // if (params.queryAmount > 1000) {
    //     // prep for multiple
    //     if(data['Items'].length > 0) {
    //         // allData = [...allData, ...data['Items']];
    //         total = [...data, ...data['Items']]; // remove duplicates
    //     }

    //     if (data.LastEvaluatedKey) {
    //         params.ExclusiveStartKey = data.LastEvaluatedKey;
    //         // return await getAllTransactions(params);
    //     } else {
    //         return data;
    //     }
    // }
}

export async function searchForTransaction(transactionId) {
}

export async function getTransactions(start, end, limit) {
    let res, lastKey = 1, url, transactions = [], range;
    try {
        console.log('getting transaction...');
        do {
            limit ? limit = `&limit=${limit}` : '';
            range = start && end ? `&start=${start}&end=${end}` : '';
            // https://toqj3gvif2.execute-api.us-east-1.amazonaws.com/dev
            // https://sounal.com/rest/get_transactions
            url = (!lastKey || lastKey == 1) ? `https://sounal.com/rest/get_transactions` :
                `https://sounal.com/rest/get_transactions?lastKey=${lastKey}${range}`;

            let headers = new Headers();
            headers.append("Authorization", "Bearer " + store.state.idToken);
            // headers.append('Access-Control-Allow-Origin', 'http://localhost:8080/');
            // headers.append("Access-Control-Allow-Headers", "Origin, X-Requested-With, Content-Type, Accept");
            let requestOptions = {
                method: 'GET',
                headers: headers,
                redirect: 'follow',
                // mode: 'cors',   // TODO - remove in actual run cases -- LOCAL DEVELOPMENT ONLY --
                // 'Content-Type': 'application/x-www-form-urlencoded',    //application/json
            };

            try {
                res = await fetchRequest(url, requestOptions);
            } catch (err) {
                console.error('error', err);
            }
            console.log('initial request', res);
            if (!res) {
                console.log('product response FAILED'); // kill whole execution group attempt?
                // lastKey = null;
            }
            transactions.push(...res.transactions);    // need to check for duplicates within already existant
            lastKey = res.lastKey ? lastKey = res.lastKey : null;
        } while (lastKey);
    } catch (err) {
        console.error("something has gone awry in Transaction Services", err);
        return false;
    }
    return transactions;
}