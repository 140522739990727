<template>
    <div class="full-cover">
        <div class="modal" style="display: block">
            <div @click="closeModal" class="close-div">
                <span id="closeModal" class="close">&times;</span>
            </div>
            <component class="content" :is="target" />
        </div>
    </div>
</template>

<script>
import { mapMutations } from "vuex";
// import store from "../.././store";
import { useStore } from "vuex";

export default {
    name: "Modal",
    setup() {
        const store = useStore();
    },
    props: {
        target: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            opened: false,
        };
    },
    mounted() {
        // this.data.data = store.state.data;
        // console.log("data loaded for generic modal component", this.data.data);
    },
    methods: {
        ...mapMutations(["closeOpenModal"]),
        closeModal() {
            // this.closeOpenModal();
            // go back by one record, the same as history.back()
            store.dispatch("CLOSE_MODALS");
            this.$router.go(-1);
        },
    },
};
</script>

<style scoped>
.full-cover {
    position: fixed;
    z-index: 101;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4);
    /* opacity: 0.2; */
    /* position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4); */
}
.modal {
    position: fixed;
    z-index: 100;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    min-width: 400px; /* 50% */
    min-height: 50px; /* 50% */
    /* overflow: auto; */
    background-color: #fefefe;
    /* overflow: hidden; */
    border: 3px solid #5f9ea0;
    border-radius: 10px;
    margin: 10px;
    padding: 20px;
    overflow: scroll;
    /* box-shadow: 4px 2px 2px darkgrey; */
    /* background-color: rgba(0, 0, 0, 0.4); */
}
.content {
    /* margin: 10px; */
    height: 100%;
    width: 100%;
}
.modal-title {
    font-size: 1.5em;
    color: black;
    text-align: center;
}

.modal-header {
    text-align: center;
}

.modal-content {
    background-color: #fefefe;
    border-radius: 9px;
    position: fixed;
    top: 10%;
    left: 5%;
    width: 90%;
    height: 80%;
    /* display: grid;
    grid-gap: 1em;
    grid-template-columns: 1fr 3fr;
    text-align: center;
    align-items: center;
    align-content: center; */
    font-size: 1.5em;
}
.close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
    padding-right: 10px;
}
.close:hover,
.close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}
</style>
