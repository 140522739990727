<template>
    <div :class="fullPage ? 'full-cover' : 'cover-parent'">
        <!-- <v-icon icon="spinner" scale="2" spin class="centered-loader"></v-icon> -->
    </div>
</template>

<script>
export default {
    name: "Loader",
    components: {},
    props: {
        /**
         * if fullPage is true, cover entire window with loader, otherwise
         * just cover parent component with loader and do not fade background
         */
        fullPage: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style scoped>
.full-cover {
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.1);
}
.cover-parent {
    z-index: 100;
    height: inherit;
    width: inherit;
}
.centered-loader {
    position: fixed;
    z-index: 100;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    min-width: 400px; /* 50% */
    min-height: 50px; /* 50% */
    /* overflow: auto; */
    /* background-color: #fefefe; */
    /* overflow: hidden; */
    /* border: 3px solid #5f9ea0; */
    /* border-radius: 10px; */
    margin: 10px;
    padding: 20px;
    overflow: scroll;
    /* box-shadow: 4px 2px 2px darkgrey; */
    /* background-color: rgba(0, 0, 0, 0.4); */
}
</style>
