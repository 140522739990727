<template>
    <div class="code-row">
        <div class="options-row">
            <v-icon
                v-on:click="toggleModal('EditCodeStatus', code)"
                class="icon check-img"
                scale="2.1"
                :class="isActiveIcon"
                :name="isActiveIcon"
            ></v-icon>
            <v-icon
                v-on:click="toggleModal('qrDisplayer', code)"
                class="icon edit-img tooltip fade"
                data-title="'View Code'"
                scale="1.5"
                :name="'eye'"
            ></v-icon>
        </div>

        <div class="input-row">
            <div class="code-input-holder">
                <input
                    disabled
                    class="input-field"
                    @paste.prevent
                    v-model="code.URL"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { useStore } from "vuex";

export default {
    name: "CodeRow",
    setup() {
        const store = useStore();
    },
    data() {
        return {
            displayModal: false,
            activeComponent: null,
            data: {},
        };
    },
    props: {
        code: {
            type: Object,
            default: null,
        },
    },
    computed: {
        isActiveIcon() {
            return this.code.status === "active"
                ? "check-square"
                : "times-circle";
        },
    },
    watch: {
        "$store.state.forceCloseModals"() {
            this.displayModal = false;
        },
    },
    methods: {
        toggleModal(desiredComponent, data) {
            store.state.data = data; // set global modal data
            this.$emit("toggleModal", desiredComponent);
        },
    },
};
</script>

<style scoped>
.icon {
    background-color: none;
}
.icon:hover {
    cursor: pointer;
    border-radius: 6px;
    /* background-color: springgreen; */
    color: #5f9ea0;
    transition: 0.3s;
}
.check-square {
    /*  */
    color: greenyellow;
}
.times-circle {
    /*  */
    color: tomato;
}
.icon-active {
    color: green;
}
.icon-inactive {
    color: red;
}
.center-grid-items {
    grid-column: 1/-1;
}
.section-holder {
    border: none;
    align-self: center;
    padding: 0;
    background-color: rgba(0, 0, 0, 0.69);
}
.row {
    grid-template-columns: 1fr;
    text-align: center;
    align-items: center;
    align-content: center;
}
.flag-section {
    height: 17px;
    font-family: inherit;
    font-size: 0.8rem;
    color: #fff;
    background: transparent;
    transition: border-color 0.2s;
}
.hint {
    font-family: inherit;
    font-size: 0.7rem;
    color: #fff;
    background: transparent;
    transition: border-color 0.2s;
}
.input-row {
    display: grid;
    grid-template-columns: 1fr;
    text-align: center;
    align-items: center;
    align-content: center;
    justify-content: center;
}
.input-label {
    font-size: 1.4rem;
    font-weight: 600;
    color: #f4f4f4;
}
.code-input-holder {
    min-height: 27px;
    overflow: hidden;
    border-radius: 3px;
    border: 1px solid #9b9b9b;
}
.code-input-holder > .input-field {
    height: 41px;
    width: 100%;
    font-size: 1.2em;
    padding-left: 10px;
    border: none;
}
.code-row {
    display: grid;
    grid-template-columns: 1fr;
    text-align: center;
    align-items: center;
    align-content: center;
    justify-content: center;
    padding-left: 10px;
    padding-right: 5px;
    padding-top: 6px;
}
.options-row {
    display: grid;
    grid-template-columns: 37px 47px 37px 37px 1fr;
    text-align: center;
    align-items: center;
    align-content: center;
    justify-content: center;
}
</style>
