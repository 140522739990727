<template>
    <div ref="full-listing" class="product-table">
        <div class="product-title" v-on:click="toggleExpand">
            <div
                class="status"
                :class="product.available ? 'status-active' : 'status-inactive'"
            ></div>
            <div class="product-name">{{ product.name }} {{ product.sku }}</div>
            <v-icon
                scale="1.5"
                class="grid-scale-img"
                :name="expandedToggle"
            ></v-icon>
        </div>
        <div
            ref="adntl-details"
            class="product-details"
            :style="[expanded ? { height: computedHeight } : {}]"
            :class="expanded ? '' : 'collapsed'"
        >
            <div class="details-header">Details</div>
            <div class="group-header">
                Description:
                <v-icon
                    class="icon edit-img"
                    scale="1.5"
                    :name="'wrench'"
                ></v-icon>
            </div>
            <div class="description-section">
                <input
                    disabled
                    class="description-input"
                    v-model="product.description"
                />
            </div>

            <div class="group-header">
                Backup URL: (if URL is set to inactive)
                <v-icon
                    class="icon edit-img"
                    scale="1.5"
                    :name="'wrench'"
                ></v-icon>
            </div>
            <div class="description-section">
                <input
                    disabled
                    class="description-input"
                    v-model="product.redirectURL"
                />
            </div>
            <div class="group-header">Codes:</div>
            <div class="product-codes-section">
                <div></div>
                <div class="code-row">
                    <!-- New Code -->
                    <div class="new-code-details">
                        <v-icon
                            v-on:click="toggleNewQRCodeSection"
                            class="icon edit-img"
                            scale="2.0"
                            :name="'plus-square'"
                        ></v-icon>
                        <button
                            v-if="newCodeActive"
                            v-on:click="toggleNewQRCodeSection"
                            class="new-code-opt code-cancel-btn"
                        >
                            Cancel
                        </button>
                        <button
                            v-if="newCodeActive"
                            v-on:click.stop="addNewCode"
                            class="new-code-opt"
                            :class="
                                newCode.URL.length > 0 && canSubmitNewCode
                                    ? 'code-add-btn'
                                    : 'new-code-opt-disabled'
                            "
                        >
                            Add
                        </button>
                        <button
                            v-if="!newCodeActive"
                            v-on:click="toggleNewQRCodeSection"
                            class="new-code-opt code-create-btn"
                        >
                            Create New
                        </button>
                    </div>
                </div>

                <div v-show="newCodeActive" class="code-row label-row">
                    <p class="new-code-label">New Code</p>
                </div>
                <div v-if="newCodeActive" class="code-row new-code">
                    <div class="options-row">
                        <v-icon
                            class="icon check-img"
                            scale="2.1"
                            :name="'check-square'"
                        ></v-icon>
                        <div class="url-label">Destination</div>
                        <div
                            v-if="newCode.URL.length > 0 && !canSubmitNewCode"
                            class="url-hint"
                        >
                            {{ newURLHintMsg }}
                        </div>
                    </div>

                    <div class="input-row">
                        <div class="code-input-holder">
                            <input
                                class="input-field"
                                @paste.prevent
                                v-model="newCode.URL"
                                placeholder="Your URL here"
                            />
                        </div>
                    </div>
                </div>

                <!-- update response message -->
                <transition :name="'slide-fade'" class="code-row result-row">
                    <p v-if="displayUpdateMessage" class="new-code-label">
                        {{ updateMessage }}
                    </p>
                </transition>

                <!-- actual listings -->
                <div class="code-row label-row">
                    <p class="new-code-label">Codes</p>
                </div>

                <code-row
                    v-for="code in Object.keys(product.codes)"
                    :key="code.URL"
                    :ref="'code-row-' + code.URL"
                    class="code-row"
                    :code="getCodeObject(code, product.codes[code])"
                    @toggleModal="toggleModal"
                >
                </code-row>
            </div>
        </div>

        <Loader v-if="localRequestIdArray.length > 0" :fullPage="false" />
        <router-view
            v-if="displayModal"
            :target="activeComponent"
        ></router-view>
    </div>
</template>

<script>
// import Loader from "./Shared/Loader.vue";
import Loader from "../Shared/Spinner.vue";
import qrDisplayer from "./QRCodeDisplayer.vue";
import EditCodeStatus from "./EditCodeStatus.vue";
import CodeRow from "./CodeRow.vue";

import { useStore } from "vuex";
import { generateId } from "../../services-frontend/fetch-services";
// import { mapState } from "vuex";
import { RequestObject } from "../../models/RequestObject";

export default {
    name: "ProductListRow",
    setup() {
        const store = useStore();
    },
    components: {
        CodeRow,
        Loader,
    },
    data() {
        return {
            expanded: false,
            computedHeight: 0,
            newCodeActive: false,
            newCode: {
                status: "active",
                id: "",
                URL: "",
                note: "",
            },
            tempCodeCount: 0,
            displayModal: false,
            activeComponent: null,
            data: null,
            requestPending: false,
            localRequestIdArray: [],
            localRequestIdObject: {},
            displayUpdateMessage: false,
            itemDescriptionEnabled: false,
            itemBackupURLEnabled: false,
            updateMessage: "",
            newURLHintMsg:
                "URL must be in format similar to 'https://example.com/'",
            responseFunctionMap: {
                // "update-item": getSpecifiedProducts,
                "update-item": "get-items",
            },
            // openTransition: "",
        };
    },
    props: {
        product: {
            type: Object,
            default: null,
        },
        OpenByDefault: {
            type: Boolean,
            default: false,
        },
    },
    mounted() {
        this.initHeight();
        this.tempCodeCount = Object.keys(this.product).length;
    },
    computed: {
        expandedToggle() {
            return this.expanded ? "chevron-up" : "chevron-down";
        },
        newCodeToggleText() {
            return this.newCodeActive ? "Cancel" : "Create New Code";
        },
        /**
         * checks incoming URL if it is valid
         */
        canSubmitNewCode() {
            try {
                new URL(this.newCode.URL);
            } catch (e) {
                return false;
            }
            return true;
        },
        toggleActiveAddNewIcon() {},
        toggleActiveIcon() {},
        activeHeightofProductView() {
            // calculates current height of listng, needed with adding/removing codes from product
        },
        calculateHeight() {},
    },
    methods: {
        /**
         * handles incoming new codes from backend.
         * Adds or removes necessary height adjustment
         */
        adjustSpaceForUpdatedCodesDuringSession(flag = 1) {
            // clear out new code inputs
            this.cancelNewCode();

            // 85px
            let heightToAdjust = 85 * flag;
            let num = parseInt(this.computedHeight.split(".")[0]);
            num += heightToAdjust;
            this.computedHeight =
                num.toString() + "." + this.computedHeight.split(".")[1];
        },
        toggleNewQRCodeSection() {
            let heightToAdjust = 160;

            if (this.newCodeActive) {
                // clear out any new code data
                this.cancelNewCode();

                // reset height
                this.newCodeActive = false;
                let num = parseInt(this.computedHeight.split(".")[0]);
                num -= heightToAdjust;
                this.computedHeight =
                    num.toString() + "." + this.computedHeight.split(".")[1];
            } else {
                // add with new heights
                this.newCodeActive = true;
                let num = parseInt(this.computedHeight.split(".")[0]);
                num += heightToAdjust;
                this.computedHeight =
                    num.toString() + "." + this.computedHeight.split(".")[1];
            }
        },
        getCodeObject(key, codeObj) {
            return {
                status: codeObj.status,
                codeId: key,
                URL: codeObj.URL,
                note: codeObj.note,
            };
        },
        toggleExpand() {
            this.expanded = this.expanded ? false : true;
        },
        toggleEditField(fieldToToggle, val) {
            /*
            itemDescriptionEnabled: false,
            itemBackupURLEnabled: false,
            */
            return;
        },
        initHeight: function () {
            this.$refs["adntl-details"].style.height = "auto";
            this.$refs["adntl-details"].style.position = "absolute";
            this.$refs["adntl-details"].style.visibility = "hidden";
            this.$refs["adntl-details"].style.display = "block";

            const height = getComputedStyle(this.$refs["adntl-details"]).height;
            this.computedHeight = height;

            this.$refs["adntl-details"].style.position = null;
            this.$refs["adntl-details"].style.visibility = null;
            this.$refs["adntl-details"].style.display = null;
            this.$refs["adntl-details"].style.height = 0;
        },
        cancelNewCode() {
            this.newCodeActive = false;
            // clear out any inputs
            this.newCode.status = "inactive";
            this.newCode.id = "";
            this.newCode.URL = "";
            this.newCode.note = "";
        },
        addNewCode() {
            let newCodeId = `code--${new Date().toISOString()}--${
                this.product.productId
            }--${this.newCode.URL}`;
            let req = new RequestObject({
                id: generateId(), // purely front-end request for client loaders
                requestType: "update-item", // or remove-item and then include just the codes to be deleted (if we actually delete codes that is)
                // specificRequest: "new-code", // no check versions!
                payload: {
                    id: this.product.productId,
                    version: this.product.version,
                    codes: {},
                    postCall: "updateProduct", // if true, another call is
                },
            });
            // use product specific id for new codes
            req.payload.codes[newCodeId] = {
                status: "active",
                note: this.newCode.note,
                URL: this.newCode.URL,
            };
            this.sendUpdate(req);
        },
        sendUpdate(obj) {
            console.log("submitting locally id", obj.id);

            // this.localRequestIdArray.push(obj.id);
            if (!this.localRequestIdObject[obj.id]) {
                this.localRequestIdObject[obj.id] = {};
            }
            this.localRequestIdObject[obj.id] = {
                msg: obj.msg,
                type: obj.requestType,
            };
            store.dispatch("REQUEST_UPDATE", obj);
        },
        activeCode(active) {
            return active === "active" ? "check-square" : "times-circle";
        },
        toggleModal(desiredComponent) {
            // do direct mapping if possible? TODO - use mapping or something better than a switch
            let data = this.$store.state.data;

            let routeToUse = "";
            switch (desiredComponent) {
                case "qrDisplayer":
                    this.activeComponent = qrDisplayer;
                    data.codeId = data.codeId.replaceAll("/", "-");
                    routeToUse = `/products/code/${data.codeId}`;
                    break;
                case "EditCodeStatus":
                    this.activeComponent = EditCodeStatus;
                    // data.codeId = data.codeId.replaceAll("/", "---");
                    routeToUse = `/products/${encodeURIComponent(
                        this.product.productId
                    )}/code/edit/${encodeURIComponent(
                        data.URL
                    )}?code=${encodeURIComponent(data.codeId)}`;
                    break;
                default:
                    this.activeComponent = null;
                    break;
            }
            // this.$emit("toggleModal");
            this.$router.push(routeToUse);
            this.displayModal = this.displayModal ? false : true;
        },
        responseResultTransition() {
            this.displayUpdateMessage = true;
            setTimeout(function () {
                this.displayUpdateMessage = false;
            }, 3000); // 3 seconds delay
        },
    },
    watch: {
        "$store.state.forceCloseModals"() {
            this.displayModal = false;
        },
        "product.codes": {
            handler(val) {
                console.log(
                    "product change detected in product listing component...",
                    val
                );
                console.log("original length", this.tempCodeCount);

                // TODO - check if open, and if so, reopen tile on changes
                let addOrRemove =
                    Object.keys(val).length > this.tempCodeCount ? 1 : -1;
                this.adjustSpaceForUpdatedCodesDuringSession(addOrRemove);
            },
            deep: true,
        },
        requestsObj: {
            async handler(newVal, oldVal) {
                if (Object.keys(this.localRequestIdObject).length < 1) {
                    return;
                }
                Object.keys(newVal).forEach((item) => {
                    if (item !== "version") {
                        Object.keys(this.localRequestIdObject).forEach(
                            (request) => {
                                if (item === request) {
                                    console.log("matching IDs found", request);
                                    // now can remove
                                    this.updateMessage =
                                        this.localRequestIdObject[request].msg;
                                    // this.responseResultTransition();

                                    // now remove update request from local component
                                    delete this.localRequestIdObject[request];
                                }
                            }
                        );
                    }
                });
            },
            deep: true,
        },
    },
};
</script>

<style scoped>
.icon {
    background-color: none;
}
.icon:hover {
    cursor: pointer;
    border-radius: 6px;
    /* background-color: springgreen; */
    color: #5f9ea0;
    transition: 0.3s;
}
.check-square {
    /*  */
}
.times-circle {
    /*  */
}
.icon-active {
    color: green;
}
.icon-inactive {
    color: red;
}
.product-table {
    display: grid;
    grid-template-columns: 1fr;
    text-align: center;
    align-items: center;
    align-content: center;
    overflow: hidden;
}
.product-title {
    display: grid;
    grid-template-columns: 37px 1fr 37px;
    text-align: center;
    align-items: center;
    align-content: center;
    background-color: lightslategrey;
    height: 10vh;
    z-index: 4;
    cursor: pointer;
}
.status-inactive {
    background-color: lightcoral;
}
.status-active {
    background-color: chartreuse;
}
.status {
    width: 37px;
    background-color: lightgray;
}
.span-all-cols {
    columns: 1/-1;
}
.product-details {
    display: grid;
    grid-template-columns: 1fr;
    text-align: center;
    align-items: center;
    align-content: start;
    background-color: #f4f4f4;
    border-color: slategrey;
    border-width: 1px;
    z-index: 3;
    height: 0;
    overflow: hidden;
    transition: 0.4s;
}
.edit-icon {
    font-size: 2em;
}
.details-header {
    text-align: left;
    padding: 30px 0 0 40px;
    font-size: 1.5em;
}
.group-header {
    text-align: left;
    padding: 10px 0 0 40px;
    font-size: 1.1em;
}
.description-section {
    margin-left: 40px;
    margin-right: 40px;
    text-align: left;
    font-size: 1.2em;
    border-radius: 3px;
    padding-bottom: 10px;
    overflow: hidden;
    border: 3px solid #5f9ea0;
    height: 50px;
}
.description-input {
    height: 60px;
    width: 100%;
    font-size: 1.1em;
    border-radius: 4px;
    border: none;
    padding-left: 10px;
}
.product-codes-title {
    display: grid;
    grid-template-columns: 37px 1fr;
    text-align: center;
    align-items: center;
    align-content: center;
}
.product-codes-section {
    padding: 10px 16px 10px 40px;
    margin: 0 40px 0 40px;
    border-radius: 4px;
    display: grid;
    grid-template-columns: 1fr;
    text-align: center;
    align-items: center;
    align-content: center;
    background-color: #f4f4f4;
    border: 3px solid #5f9ea0;
}

/* code row section */
.code-row {
    display: grid;
    grid-template-columns: 1fr;
    text-align: center;
    align-items: center;
    align-content: center;
    justify-content: center;
    padding-left: 10px;
    padding-right: 5px;
    padding-top: 6px;
}
.options-row {
    display: grid;
    grid-template-columns: 37px 80px 1fr;
    text-align: center;
    align-items: center;
    align-content: center;
    justify-content: center;
}
.input-row {
    display: grid;
    grid-template-columns: 1fr;
    text-align: center;
    align-items: center;
    align-content: center;
    justify-content: center;
}
.section {
    display: flex;
    justify-content: center;
    align-items: center;
}
.url-label {
    text-align: left;
    size: 5px;
}
.code-input-holder {
    min-height: 27px;
    overflow: hidden;
    border-radius: 3px;
}
.code-input-holder > .input-field {
    height: 33px;
    width: 100%;
    font-size: 1.2em;
    padding-left: 10px;
}
.code-row > .new-code-label {
    font-size: 1.2em;
    padding-bottom: 2px;
    margin-bottom: 2px;
}
.new-code {
    padding-bottom: 5px;
    background-color: #f4f4f4;
    border: 3px solid #5f9ea0;
    border-radius: 4px;
}
.label-row {
    margin-top: 0;
    padding-top: 15px;
    padding-bottom: 15px;
    height: 35px;
}
.result-row {
    margin-top: 0;
    padding-top: 15px;
    padding-bottom: 15px;
    height: 35px;
    font-size: 1.4em;
}
.new-code-details {
    display: grid;
    grid-template-columns: 30px 1fr 1fr 3fr;
    text-align: center;
    align-items: center;
    align-content: center;
    justify-content: start;
}
.new-code-opt {
    height: 30px;
    border-radius: 10px;
    border: 1px solid #5f9ea0;
    font-size: 1.1em;
    color: black;
}
.new-code-opt-disabled {
    background-color: lightgrey;
    cursor: not-allowed;
}
.new-code-opt:hover {
    cursor: pointer;
}
.new-code-opt-disabled:hover {
    cursor: not-allowed;
}
.url-hint {
    color: red;
}
.code-cancel-btn {
    background-color: #fc3623;
}
.code-cancel-btn:hover {
    background-color: #fc3623;
    cursor: pointer;
}
.code-add-btn {
    background-color: #3ffe78;
}
.code-create-btn {
    background-color: #09e69a;
}
/* END code row section */
/* durations and timing functions.              */
.slide-fade-enter-active {
    transition: all 0.3s ease;
}
.slide-fade-leave-active {
    transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateX(10px);
    opacity: 0;
}
</style>
