<template>
    <div class="product-list-container">
        <div class="product-filter"></div>
        setting0 setting1 setting2 setting3 setting4
    </div>
</template>

<script>
// import store from "../.././store";

export default {
    name: "SettingsDashboard",
    components: {},
    data() {
        return {};
    },
    mounted() {
        console.log("loaded settings page...");
    },
};
</script>

<style>
</style>
