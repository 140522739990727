<template>
    <div class="background-triangle" style="display: block">
        <div class="modal-content">
            <div class="text-center title--div">
                <!-- <img src="/assests/images/fullWhiteLogo.svg" alt="logo and text"> -->
                <!-- <logoPlusText/> -->
                <!-- h297mm w210mm -->
                <svg
                    xmlns:dc="http://purl.org/dc/elements/1.1/"
                    xmlns:cc="http://creativecommons.org/ns#"
                    xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
                    xmlns:svg="http://www.w3.org/2000/svg"
                    xmlns="http://www.w3.org/2000/svg"
                    id="titlePlusLogo"
                    version="1.1"
                    viewBox="0 0 210 297"
                    height="200"
                    width="350"
                >
                    <defs id="defs2" />
                    <metadata id="metadata5">
                        <!-- <rdf:RDF>
                            <cc:Work rdf:about="">
                                <dc:format>image/svg+xml</dc:format>
                                <dc:type
                                    rdf:resource="http://purl.org/dc/dcmitype/StillImage"
                                />
                                <dc:title></dc:title>
                            </cc:Work>
                        </rdf:RDF> -->
                    </metadata>
                    <g id="layer1">
                        <rect
                            ry="19.450043"
                            y="74.817368"
                            x="-74.918266"
                            height="128.64595"
                            width="397.36954"
                            id="rect4535-44-56-6-4-0-6-1"
                            style="
                                fill: #ffffff;
                                fill-opacity: 1;
                                stroke: none;
                                stroke-width: 0.89749527;
                                stroke-miterlimit: 4;
                                stroke-dasharray: none;
                            "
                        />
                        <g
                            style="
                                stroke: none;
                                stroke-width: 0.69633383;
                                stroke-miterlimit: 4;
                                stroke-dasharray: none;
                            "
                            transform="matrix(1.2888866,0,0,1.2888866,-176.12921,-142.39366)"
                            id="g5409-3-3-5"
                        >
                            <path
                                style="
                                    fill: #bf81db;
                                    fill-opacity: 1;
                                    stroke: none;
                                    stroke-width: 0.69633383;
                                    stroke-miterlimit: 4;
                                    stroke-dasharray: none;
                                "
                                d="m 103.81336,183.057 c -5.915212,0 -10.676859,4.78516 -10.676859,10.72879 v 10.60086 c 9.59e-4,-7e-5 9.59e-4,-6.4e-4 0.0024,-6.4e-4 h 6.44e-4 c 0.01517,-0.001 0.03009,-0.002 0.04514,-0.003 v -0.007 H 114.41363 V 183.057 Z"
                                id="path5274-5-5-3-4-6"
                            />
                            <path
                                style="
                                    fill: #bf81db;
                                    fill-opacity: 1;
                                    stroke: none;
                                    stroke-width: 0.69633383;
                                    stroke-miterlimit: 4;
                                    stroke-dasharray: none;
                                "
                                d="m 117.93399,183.057 v 21.31774 h 21.13488 V 183.057 Z"
                                id="path5272-8-8-0-1-4"
                            />
                            <path
                                style="
                                    fill: #0eacf9;
                                    fill-opacity: 1;
                                    stroke: none;
                                    stroke-width: 0.69633383;
                                    stroke-miterlimit: 4;
                                    stroke-dasharray: none;
                                "
                                d="m 142.58934,183.057 -0.0215,21.32258 21.09299,4e-5 V 193.7855 c 0,-5.92668 -4.727,-10.69819 -10.59838,-10.69819 z"
                                id="path5270-3-1-5-4-2"
                            />
                            <path
                                style="
                                    fill: #76f612;
                                    fill-opacity: 1;
                                    stroke: none;
                                    stroke-width: 0.69633383;
                                    stroke-miterlimit: 4;
                                    stroke-dasharray: none;
                                "
                                d="M 93.136478,228.98336 H 114.39652 V 207.82411 H 93.137746 c -0.0024,6.99321 -0.0012,14.11833 -0.0012,21.15925 z"
                                id="path5268-6-8-1-2-5"
                            />
                            <path
                                style="
                                    fill: #ff4136;
                                    fill-opacity: 1;
                                    stroke: none;
                                    stroke-width: 0.69633383;
                                    stroke-miterlimit: 4;
                                    stroke-dasharray: none;
                                "
                                d="m 117.93399,207.82411 v 21.15925 h 21.10732 v -21.15925 z"
                                id="path5266-3-4-98-8-2"
                            />
                            <path
                                style="
                                    fill: #76f612;
                                    fill-opacity: 1;
                                    stroke: none;
                                    stroke-width: 0.69633383;
                                    stroke-miterlimit: 4;
                                    stroke-dasharray: none;
                                "
                                d="m 93.136501,232.56524 v 10.53828 c 0,5.9152 4.761647,10.67685 10.676859,10.67685 l 10.58316,-0.041 v -21.17412 z"
                                id="path5262-7-0-49-9-7"
                            />
                            <path
                                style="
                                    fill: #fff500;
                                    fill-opacity: 1;
                                    stroke: none;
                                    stroke-width: 0.69633383;
                                    stroke-miterlimit: 4;
                                    stroke-dasharray: none;
                                "
                                d="m 117.92306,232.56523 v 21.21513 h 21.11825 v -21.21513 z"
                                id="rect4535-44-5-7-6-0-2-9"
                            />
                            <path
                                style="
                                    fill: #0eacf9;
                                    fill-opacity: 1;
                                    stroke: none;
                                    stroke-width: 0.69633383;
                                    stroke-miterlimit: 4;
                                    stroke-dasharray: none;
                                "
                                d="m 142.56785,207.84097 v 21.14239 h 21.093 v -21.14239 z"
                                id="path5266-4-7-4-8-7-8"
                            />
                            <path
                                style="
                                    fill: #fff500;
                                    fill-opacity: 1;
                                    stroke: none;
                                    stroke-width: 0.69633383;
                                    stroke-miterlimit: 4;
                                    stroke-dasharray: none;
                                "
                                d="m 163.72724,232.5516 -21.14977,-0.0626 -0.01,21.31784 10.4517,-0.0265 c 5.91519,-0.015 10.67748,-4.76228 10.67748,-10.67748 z"
                                id="path5270-8-5-5-0-5-2"
                            />
                        </g>
                        <g
                            id="flowRoot4754-8-9"
                            style="
                                font-style: normal;
                                font-weight: normal;
                                font-size: 40px;
                                line-height: 1.25;
                                font-family: sans-serif;
                                letter-spacing: 0px;
                                word-spacing: 0px;
                                fill: #000000;
                                fill-opacity: 1;
                                stroke: none;
                            "
                            transform="matrix(0.50460778,0,0,0.50460778,-201.10704,120.34614)"
                            aria-label="Sounal"
                        >
                            <path
                                id="path2610-61"
                                style="
                                    font-style: normal;
                                    font-variant: normal;
                                    font-weight: normal;
                                    font-stretch: normal;
                                    font-family: 'Adobe Fan Heiti Std';
                                    -inkscape-font-specification: 'Adobe Fan Heiti Std';
                                    stroke-width: 4.2951088;
                                "
                                d="m 546.67149,-26.772055 c -2.60558,-0.90609 -8.26804,-1.72323 -11.40346,-1.72323 -26.11428,0 -42.7793,15.63411 -42.7793,36.4225739 0,17.8675251 13.22894,29.7221271 32.81464,37.6251951 15.63419,6.528416 22.33456,13.400672 22.33456,24.567949 0,11.854504 -8.76201,19.929305 -24.39622,19.929305 -10.82367,0 -21.47553,-3.436099 -28.51952,-7.731178 -2.49863,9.325008 0.0137,12.694868 3.93798,16.97512 5.53918,6.03767 17.19397,7.59289 23.37892,7.59289 29.55035,0 45.87174,-16.493228 45.87174,-38.484177 0,-18.039414 -10.13644,-29.550354 -31.26837,-38.827877 -16.66502,-7.215742 -24.05261,-12.713366 -24.05261,-23.8806431 0,-8.418562 6.52855,-17.5240579 22.16276,-17.5240579 10.82367,0 19.07029,3.4360399 23.36538,5.8412899 4.48139,-12.7241699 -1.69991,-17.3938599 -11.4465,-20.7831599 z"
                            />
                            <path
                                id="path2612-01"
                                style="
                                    font-style: normal;
                                    font-variant: normal;
                                    font-weight: normal;
                                    font-stretch: normal;
                                    font-family: 'Adobe Fan Heiti Std';
                                    -inkscape-font-specification: 'Adobe Fan Heiti Std';
                                    stroke-width: 4.2951088;
                                "
                                d="m 626.85047,8.2707559 c -25.08344,0 -44.66914,18.0395521 -44.66914,49.9951731 0,30.581166 18.72668,48.620641 43.29471,48.620641 21.30374,0 44.32552,-14.603377 44.32552,-49.995057 0,-29.034918 -17.00864,-48.6207571 -42.95109,-48.6207571 z m 22.50637,49.1361531 c 0,18.898474 -8.93382,34.53268 -23.36539,34.53268 -14.60337,0 -23.3654,-15.634206 -23.3654,-34.189076 0,-16.321395 6.52857,-34.532582 23.5372,-34.532582 16.66501,0 23.19359,18.55485 23.19359,34.188978 z"
                            />
                            <path
                                id="path2614-3"
                                style="
                                    font-style: normal;
                                    font-variant: normal;
                                    font-weight: normal;
                                    font-stretch: normal;
                                    font-family: 'Adobe Fan Heiti Std';
                                    -inkscape-font-specification: 'Adobe Fan Heiti Std';
                                    stroke-width: 4.2951088;
                                "
                                d="m 766.50067,10.332537 c -13.02576,0 -19.9293,1.407447 -19.9293,13.793842 V 63.28759 c 0,14.259771 -10.652,27.105752 -19.75761,27.105752 -13.05713,0 -17.69585,-10.823672 -17.69585,-27.145067 v -53.08757 c -11.59666,0 -19.92931,3.92738 -19.92931,15.483093 v 40.868744 c 0,30.237581 14.25975,40.374028 30.58117,40.374028 15.29058,0 24.56814,-9.105635 28.51966,-15.634208 h 0.51539 c 0.24913,10.797298 7.91847,13.744358 18.55487,13.744358 -0.3436,-7.559389 -0.85902,-16.493229 -0.85902,-27.14509 z"
                            />
                            <path
                                id="path2616-5"
                                style="
                                    font-style: normal;
                                    font-variant: normal;
                                    font-weight: normal;
                                    font-stretch: normal;
                                    font-family: 'Adobe Fan Heiti Std';
                                    -inkscape-font-specification: 'Adobe Fan Heiti Std';
                                    stroke-width: 4.2951088;
                                "
                                d="m 870.38309,47.098574 c 0,-29.206632 -16.49326,-38.8278181 -31.26843,-38.8278181 -14.25977,0 -24.22443,3.5707551 -28.69136,11.6454591 h -0.51539 l -0.1846,-9.583678 C 797.6489,9.7610739 792.21388,16.522593 792.21388,24.857301 V 87.5431 c 0,10.697421 9.76021,17.45362 19.92931,17.45362 V 47.957731 c 0,-15.11881 10.48005,-23.19371 20.27291,-23.19371 13.57254,0 18.03945,11.339108 18.03945,24.7398 V 85.27302 c 0,21.27136 19.92934,19.7237 19.92934,19.7237 z"
                            />
                            <path
                                id="path2618-2"
                                style="
                                    font-style: normal;
                                    font-variant: normal;
                                    font-weight: normal;
                                    font-stretch: normal;
                                    font-family: 'Adobe Fan Heiti Std';
                                    -inkscape-font-specification: 'Adobe Fan Heiti Std';
                                    stroke-width: 4.2951088;
                                "
                                d="m 961.87959,46.755108 c 0,-20.101137 -7.7312,-38.4843521 -35.5635,-38.4843521 -7.7398,0.190487 -14.6347,1.333019 -21.3128,3.5149841 -4.6915,1.722045 -8.2362,7.237147 -6.9254,12.12855 l 1.7803,5.488591 c 6.0132,-3.951534 14.6034,-6.528613 23.0218,-6.528613 19.0703,0 19.0703,14.087822 19.0703,21.131929 -32.471,0 -52.9157,11.682673 -52.9157,35.219869 0,14.603375 10.1364,27.660504 27.8323,27.660504 11.8545,0 22.4355,-2.33241 27.7614,-9.720006 h 0.5154 c 0.2878,7.591436 8.3935,7.830156 16.991,7.830156 0,-6.387616 -0.2577,-14.431566 -0.2577,-22.506367 z m -19.4139,26.286008 c 0,10.995464 -8.9338,19.242077 -19.7575,19.242077 -7.3876,0 -13.9161,-4.638702 -13.9161,-15.11879 0,-16.836831 18.0394,-19.757494 33.6736,-19.757494 z"
                            />
                            <path
                                id="path2620-0"
                                style="
                                    font-style: normal;
                                    font-variant: normal;
                                    font-weight: normal;
                                    font-stretch: normal;
                                    font-family: 'Adobe Fan Heiti Std';
                                    -inkscape-font-specification: 'Adobe Fan Heiti Std';
                                    stroke-width: 4.2951088;
                                "
                                d="m 986.83439,-32.790475 h 9.9647 c 6.69731,0 9.96461,4.83504 9.96461,8.99984 V 94.942221 c 0,6.144399 -4.3664,10.054499 -9.96461,10.054499 h -9.9647 V 36.103129 Z"
                            />
                        </g>
                    </g>
                </svg>
            </div>

            <h2 class="header--element text-center">Create Account</h2>

            <account-step :data="steps[step]" />

            <div
                class="next-step-btn option-btn-next span-all-cols"
                :class="canMoveToNextStep ? 'option-btn-next-ready' : ''"
                v-on:click="goToNextStep"
            >
                <span class="btn-inner-text">
                    {{ stepText[step] }}
                    <v-icon
                        name="arrow-right"
                        scale="1.5"
                        style="color: white"
                    ></v-icon>
                </span>
            </div>
            <div class="extra-options-btns span-all-cols">
                <div class="btn option-btn" v-on:click="goToPreviousStep">
                    <span class="btn-inner-text">Back</span>
                </div>

                <div class="btn option-btn" v-on:click="goToLoginPage">
                    <span class="btn-inner-text">Cancel</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import store from "../.././store";
import { fetchRequest } from "../../services-frontend/fetch-services";
import AccountStep from "./AccountStep.vue";
// let specCharPattern = new RegExp(/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/);

export default {
    name: "CreateAccount",
    components: {
        AccountStep,
    },
    data() {
        return {
            data: {},
            step: "step1",
            steps: {
                step1: {
                    email: {
                        required: true,
                        label: "Email",
                        regex: /\S+@\S+\.\S+/,
                        value: "",
                        flag: "",
                    },
                    password: {
                        required: true,
                        label: "Password",
                        regex: /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,16}$/,
                        value: "",
                        minLength: 8,
                        flag: "",
                        type: "password",
                        hint:
                            "(must be at-least 10 characters and contain one special character and one number)",
                    },
                },
                step2: {
                    companyName: {
                        required: true,
                        label: "Company Name",
                        regex: /[^A-Za-z0-9\s]/,
                        value: "",
                        flag: "",
                    },
                    phone: {
                        required: false,
                        label: "Phone",
                        regex: /[0-9]/,
                        minLength: 9,
                        value: "",
                        flag: "",
                    },
                },
                step3: {
                    securityQuestion: {
                        required: true,
                        label: "Security Question",
                        regex: /[^A-Za-z0-9\s!?]/, // allow num, letters, spaces, ?, !
                        value: "",
                        flag: "",
                    },
                    securityAnswer: {
                        required: true,
                        label: "Answer",
                        regex: /[^A-Za-z0-9\s!?]/, // allow num, letters, spaces, ?, !
                        value: "",
                        flag: "",
                    },
                },
            },
            stepText: {
                step1: "Awesome",
                step2: "Almost There",
                step3: "Finish!",
            },
            opened: false,
            passworkCheck: "",
            errorMsg: "",
            passwordProperStrength: false,
            passwordProperStrengthMsg: "",
            // strength: {
            //     0: "Needs Work",
            //     1: "OK",
            //     2: "Good",
            //     3: "Great!",
            // },
            disableGo: true,
            canMoveToNextStep: false,
        };
    },
    beforeUnmount() {
        this.resetStep();
    },
    watch: {
        steps: {
            deep: true,
            handler(event) {
                let check = false;
                Object.keys(this.steps[this.step]).forEach((field) => {
                    if (
                        this.steps[this.step][field].flag.length > 0 ||
                        this.steps[this.step][field].value.length <= 0
                    ) {
                        check = true;
                    }
                });
                this.canMoveToNextStep = !check ? true : false;
            },
        },
    },
    methods: {
        clearAllFlags() {},

        async goToNextStep() {
            console.log("attempting to go to next step...");
            let canProgress = false;
            // initial data length check
            // if (this.steps[this.step].email.value.length && this.steps[this.step].password.value.length) {
            //     this.steps[this.step].email.flag = 'data required';
            //     return;
            // }
            // additional last checks
            switch (this.step) {
                case "step1":
                    // check email and password
                    canProgress = (await this.validateEmailDoesNotExistAsync())
                        ? false
                        : true;
                    if (!canProgress) {
                        this.steps.step1.email.flag = "Email already in use";
                        break;
                    } else {
                        this.steps.step1.email.flag = "";
                    }
                    if (
                        this.steps.step1.email.flag ||
                        this.steps.step1.password.flag
                    ) {
                        canProgress = false;
                    }
                    break;
                case "step2":
                    break;
                case "step3":
                    break;
                default:
                    break;
            }

            if (canProgress) {
            }
            // step 1: ensure all current data is acceptable

            // step 2: make any needed backend calls to ensure email, company name, etc is unique

            // step 3: if good, go to next or finish
            if (this.step === "step3") {
                // go to finish
            } else {
                this.iterateStep();
            }
        },
        /**
         * iterates the step by incrementing the integer value appended to the string
         */
        iterateStep() {
            let next = this.step.split("p")[1]++;
            this.step = "step" + next;
        },
        /**
         * returns to previous step
         */
        goToPreviousStep() {
            switch (step) {
                case "step2":
                    this.step = "step1";
                    break;
                case "step3":
                    this.step = "step2";
                    break;
                default:
                    break;
            }
        },
        /**
         * resets step string back to "step1"
         */
        resetStep() {
            this.step = "step1";
        },
        /**
         * returns to login page
         */
        goToLoginPage() {
            this.$router.push("/login").catch(() => {});
        },
        createAccountAttempt() {},

        /**
         * returns true if email already exists
         * else returns false
         */
        async validateEmailDoesNotExistAsync() {
            let result;
            console.log("attempting to validate data...");

            let url = "https://sounal.com/rest/precheck_account_services";
            let headers = new Headers();
            headers.append("RequestType", "validateEmail");
            headers.append("Payload", btoa(this.steps.step1.email.value));
            headers.append("Content-Type", "application/json");
            headers.append("Accept-Charset", "utf-8");
            let requestOptions = {
                method: "GET",
                headers: headers,
                redirect: "follow",
            };
            try {
                result = await fetchRequest(url, requestOptions);
                console.log("response from fetch", result);
                if (result.statusCode === 200) {
                    // email already exists
                    return true;
                }
                // email does not exist
                return false;
            } catch (err) {
                console.error("error", err);
                return false;
            }
        },

        limitLoginAttempts() {
            // TODO - spike
        },
    },
};
</script>

<style scoped>
.background-triangle {
    background-image: linear-gradient(135deg, pink 50%, grey 50%);
    background-repeat: repeat-y;
    height: 100%;
}
.span-all-cols {
    columns: 1 / -1;
}
.next-step-btn {
    display: grid;
    grid-template-columns: 1fr;
    text-align: center;
    align-items: center;
    align-content: center;
    background-color: #16a085;
    padding: 20px 50px 20px 50px;
    text-align: center;
    cursor: pointer;
}
.btn-inner-text {
    font-size: 1.2rem;
    font-weight: 600;
    color: #f4f4f4;
    height: 100%;
}
.extra-options-btns {
    display: grid;
    grid-template-columns: 1fr 1fr;
    text-align: center;
    align-items: center;
    align-content: center;
    padding: 20px 20px 0px 20px;
}

/* "go to next step" icon config */
.icon-collapse::before {
    border-radius: 0;
}
.icon-collapse:hover::before {
    box-shadow: inset 0 30px 0 0 #f1f1f1, inset 0 -30px 0 0 #f1f1f1;
}
.icon-collapse::after {
    box-shadow: inset 0 0 0 1px #f1f1f1;
}
.btn {
    line-height: 40px;
    height: 40px;
    text-align: center;
    cursor: pointer;
}

/* back/cancel step button fancy stuff */
.option-btn {
    background-color: transparent;
    color: #fff;
    transition: all 0.3s;
    align-self: center;
    border-radius: 5px;
    border-width: 2px;
    border-color: #fff;
}
.option-btn span {
    transition: all 0.3s;
    background-color: #ff6766;
    padding: 4px;
    border-radius: 5px;
    border-style: solid;
    border-width: 1px;
    border-color: #fff;
}
.option-btn::before {
    content: "";
    z-index: 1;
    opacity: 0;
    transition: all 0.3s;
    transform: scale(0.1, 1);
}
.option-btn:hover span {
    letter-spacing: 2px;
}
.option-btn:hover::before {
    opacity: 1;
    transform: scale(1, 1);
}
.option-btn::after {
    content: "";
    z-index: 1;
    transition: all 0.3s;
}
.option-btn:hover::after {
    opacity: 0;
    transform: scale(0.1, 1);
}
/* END next step button fancy stuff */

/* next step button fancy stuff */
.option-btn-next {
    background-color: lightgrey;
    color: #fff;
    transition: all 0.3s;
}
.option-btn-next-ready {
    background-color: #16a085;
    color: #fff;
    transition: all 0.3s;
}
.option-btn-next span {
    transition: all 0.3s;
}
.option-btn-next::before {
    content: "";
    z-index: 1;
    opacity: 0;
    transition: all 0.3s;
    transform: scale(0.1, 1);
}
.option-btn-next:hover span {
    letter-spacing: 2px;
}
.option-btn-next:hover::before {
    opacity: 1;
    transform: scale(1, 1);
}
.option-btn-next::after {
    content: "";
    z-index: 1;
    transition: all 0.3s;
    background-color: rgba(255, 255, 255, 0.1);
}
.option-btn-next:hover::after {
    opacity: 0;
    transform: scale(0.1, 1);
}
/* END next step button fancy stuff */
</style>
