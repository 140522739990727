<template>
    <div class="transaction-holder">
        <!-- <TransactionTable /> -->
    </div>
</template>

<script>
import TransactionTable from "./TransactionTable.vue";
import store from ".././store";
// import 'vue-awesome/icons';

export default {
    name: "TransactionsView",
    components: {
        TransactionTable,
    },
    props: ["transactions"],
    data() {
        return {
            opened: false,
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
            localStorage.setItem("route", "/Transaction");
            let uid = this.$route.params.id;
            console.log(this.$route);
        },
    },
};
</script>

<style scoped>
.modal {
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
    background-color: #fefefe;
    margin: 15% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
}

.close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}
</style>
