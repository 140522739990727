<template>
    <div id="app" @click="activateUserSession">
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <!-- <div class="full-page-modal" v-if="fullPageModalActive">
            <router-view></router-view>
        </div> -->
        <router-view></router-view>
    </div>
</template>

<script>
import { logout } from "./auth/auth";
import { mapMutations, mapState } from "vuex";
export default {
    name: "App",
    components: {},
    data() {
        return {};
    },
    mounted() {},
    computed: {
        ...mapState([]), // "fullPageModalActive"
    },
    methods: {
        activateUserSession: function () {
            setTimeout(this.endUserSession, 3600000);
        },
        maintainActiveUserSession: function () {
            this.activateUserSession();
        },
        endUserSession: function () {
            console.log("attempting timer based logout...");
            logout();
        },
    },
};
</script>

<style>
#app {
    /* font-family: 'Avenir', Helvetica, Arial, sans-serif; */
    font-family: "Poppins", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
}
.full-page-modal {
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: #fefefe;
}

/* tooltips courtesy of Markus Bruch - https://codepen.io/neogomo/pen/BjqJzr */
.tooltip {
    position: relative;
}
.tooltip:before,
.tooltip:after {
    display: block;
    opacity: 0;
    pointer-events: none;
    position: absolute;
}
.tooltip:after {
    border-right: 6px solid transparent;
    border-bottom: 6px solid rgba(0, 0, 0, 0.75);
    border-left: 6px solid transparent;
    content: "";
    height: 0;
    top: 20px;
    left: 20px;
    width: 0;
}
.tooltip:before {
    background: rgba(0, 0, 0, 0.75);
    border-radius: 2px;
    color: #fff;
    content: attr(data-title);
    font-size: 14px;
    padding: 6px 10px;
    top: 26px;
    white-space: nowrap;
}

/* the animations */
/* fade */
.tooltip.fade:after,
.tooltip.fade:before {
    transform: translate3d(0, -10px, 0);
    transition: all 0.15s ease-in-out;
}
.tooltip.fade:hover:after,
.tooltip.fade:hover:before {
    opacity: 1;
    transform: translate3d(0, 0, 0);
}

/* expand */
.tooltip.expand:before {
    transform: scale3d(0.2, 0.2, 1);
    transition: all 0.2s ease-in-out;
}
.tooltip.expand:after {
    transform: translate3d(0, 6px, 0);
    transition: all 0.1s ease-in-out;
}
.tooltip.expand:hover:before,
.tooltip.expand:hover:after {
    opacity: 1;
    transform: scale3d(1, 1, 1);
}
.tooltip.expand:hover:after {
    transition: all 0.2s 0.1s ease-in-out;
}

/* swing */
.tooltip.swing:before,
.tooltip.swing:after {
    transform: translate3d(0, 30px, 0) rotate3d(0, 0, 1, 60deg);
    transform-origin: 0 0;
    transition: transform 0.15s ease-in-out, opacity 0.2s;
}
.tooltip.swing:after {
    transform: translate3d(0, 60px, 0);
    transition: transform 0.15s ease-in-out, opacity 0.2s;
}
.tooltip.swing:hover:before,
.tooltip.swing:hover:after {
    opacity: 1;
    transform: translate3d(0, 0, 0) rotate3d(1, 1, 1, 0deg);
}
</style>
