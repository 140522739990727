<template>
  <div id="companyModal" class="modal" style="display: none">
      <div class="modal-content modal-input form-element">

          <!-- should not be able to change company name -->
          <div class="row modal-element">
              <label for="am-company-name" class="col-xs-8 form-element">Company Name</label>
              <input id="am-company-name" type="text" class="col-xs-10 form-element modal-textfield" pattern="[a-zA-Z0-9-]+" placeholder="Company Name" disabled>
              <div id="am-company-name-id" class="toggle-edit toggle-edit-perm-lock col-xs-2 fa fa-lock fa-lg"></div>
              <div class="col-xs-5"></div><div id="am-company-name-saving-icon" class="col-xs-2 saving-icon"></div><div id="am-company-name-success-text" class="form-success-text col-xs-5"></div>
          </div>
          <br>
          <div class="row modal-element">
              <label for="am-company-display-name" class="col-xs-8 form-element">Company Display Name</label>
              <div id="am-company-display-name-reset-field" class="col-xs-2"></div>
              <input id="am-company-display-name" type="text" class="col-xs-10 form-element modal-textfield" pattern="[a-zA-Z0-9-]+" placeholder="Company Display Name" disabled>
              <div id="am-company-display-name-id" class="toggle-edit col-xs-2 fa fa-lock fa-lg"></div>
              <div class="col-xs-5"></div><div id="am-company-display-name-saving-icon" class="col-xs-2 saving-icon"></div><div id="am-company-display-name-success-text" class="form-success-text col-xs-5"></div>
          </div>
          <br>

          <!-- should not be able to change owner account email -->
          <div class="row modal-element">
              <label for="am-owner-account" class="col-xs-8 form-element">Owner Account</label>
              <input id="am-owner-account" type="text" class="col-xs-10 form-element modal-textfield" pattern="[a-zA-Z0-9-]+" placeholder="Owner Account" disabled>
              <div id="am-owner-account-id" class="toggle-edit toggle-edit-perm-lock col-xs-2 fa fa-lock fa-lg"></div>
              <div class="col-xs-5"></div><div id="am-owner-account-saving-icon" class="col-xs-2 saving-icon"></div><div id="am-owner-account-success-text" class="form-success-text col-xs-5"></div>
          </div>
          <br>
          <div class="row modal-element">
              <label for="am-paypal-email" class="col-xs-8 form-element">PayPal Email</label>
              <div id="am-paypal-email-reset-field" class="col-xs-2"></div>
              <input id="am-paypal-email" type="text" class="col-xs-10 form-element modal-textfield" pattern="[a-zA-Z0-9-]+" placeholder="PayPal Email" disabled>
              <div id="am-paypal-email-id" class="toggle-edit col-xs-2 fa fa-lock fa-lg"></div>
              <div class="col-xs-5"></div><div id="am-paypal-email-saving-icon" class="col-xs-2 saving-icon"></div><div id="am-paypal-email-success-text" class="form-success-text col-xs-5"></div>
          </div>
          <br>
          <div class="row modal-element">
              <label for="am-company-url" class="col-xs-8 form-element">Company URL</label>
              <div id="am-company-url-reset-field" class="col-xs-2"></div>
              <input id="am-company-url" type="text" class="col-xs-10 form-element modal-textfield" pattern="[a-zA-Z0-9-]+" placeholder="Company URL" disabled>
              <div id="am-company-url-id" class="toggle-edit col-xs-2 fa fa-lock fa-lg"></div>
              <div class="col-xs-5"></div><div id="am-company-url-saving-icon" class="col-xs-2 saving-icon"></div><div id="am-company-url-success-text" class="form-success-text col-xs-5"></div>
          </div>
          <span id="closeCompanyEditModal" class="close">&times;</span>
      </div>
  </div>
</template>

<script>
export default {
  name: 'LoginModal',
  props: {
    msg: String,
    username: String,
    pw: String,
  },
  data() {
    return {
      opened: false,
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.modal {
        /* display: none; */
        position: fixed;
        z-index: 1;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        overflow: auto;
        background-color: rgb(0,0,0);
        background-color: rgba(0,0,0,0.4);
    }

    .modal-content {
        background-color: #fefefe;
        margin: 15% auto;
        padding: 20px;
        border: 1px solid #888;
        width: 80%;
    }

    .close {
        color: #aaa;
        float: right;
        font-size: 28px;
        font-weight: bold;
    }

    .close:hover,
    .close:focus {
        color: black;
        text-decoration: none;
        cursor: pointer;
    }
</style>
