/* eslint-disable */
import router from '../routes';

export function readSpecifiedCookie(target) {
    const allcookies = document.cookie.trim();
    // store.state.cookieArray = allcookies.split(';');
    const cookieName = target;
    function escape(s) { return s.replace(/([.*+?\^${}()|\[\]\/\\])/g, '\\$1'); };
    let match = document.cookie.match(RegExp('(?:^|;\\s*)' + escape(cookieName) + '=([^;]*)'));
    return match ? match[1] : 'not found';
}

// TODO - check if function is actually useful for any reason whatsoever
export async function verifyCurrentTokens(tokens) {
    if (location.hostname === "localhost" || location.hostname === "127.0.0.1") {
        return true;
    }
    let result;
    // https://c77owe2rwg.execute-api.us-east-1.amazonaws.com/dev w/ idToken
    try {
        result = await fetch('https://c77owe2rwg.execute-api.us-east-1.amazonaws.com/dev?idToken=' + tokens.idToken)
            .then(function (response) {
                if (response.ok) {
                    return response.text();
                }
                return 'error';
            });
    } catch (err) {
        console.error("something has gone awry", err);
    }
    if (result === 'success') {
        return true;
    }
    return false;
}

export async function refreshUser() {
    let updatedTokens;
    // https://wj9dh444i1.execute-api.us-east-1.amazonaws.com/dev w/ username and refreshToken
    try {
        // console.log('accessToken:\n', store.state.accessToken);
        let tokens = {};
        tokens.idToken = readSpecifiedCookie('idCookie');
        tokens.accessToken = readSpecifiedCookie('accessCookie');
        tokens.refreshToken = readSpecifiedCookie('refreshCookie');
        tokens.currentUser = readSpecifiedCookie('usernameCookie');
        if (!tokens.currentUser || !tokens.refreshToken) {
            console.log('no detected local credentials');
            return false;
        }

        console.log('refresh user request:' + `https://wj9dh444i1.execute-api.us-east-1.amazonaws.com/dev?username=' + ${tokens.currentUser} + "&refreshToken=" + ${tokens.refreshToken}`);
        updatedTokens = await fetch('https://wj9dh444i1.execute-api.us-east-1.amazonaws.com/dev?username=' + tokens.currentUser + "&refreshToken=" + tokens.refreshToken)
            .then(function (response) {
                if (response.ok) {
                    return response.text();
                }
                return false;
            });
    } catch (err) {
        console.error("something has gone awry", err);
        return false;
    }
    if (!updatedTokens) {
        this.closeAnyOpenModal();
        this.pw = '';
        this.showLoadingIcon = false;
        this.disabledInputs = false;
        return false;
    }
    console.log('refreshed with', updatedTokens);
    const today = new Date();
    const expire = new Date();
    expire.setDate(today.getDate() + 1);
    // store.state.idToken = updatedTokens.idToken;
    // store.state.accessToken = updatedTokens.accessToken;
    // store.state.refreshToken = updatedTokens.refreshToken;
    document.cookie = "idCookie=" + updatedTokens.idToken + ";expires=" + expire.toUTCString();
    document.cookie = "accessCookie=" + updatedTokens.accessToken + ";expires=" + expire.toUTCString();
    document.cookie = "usernameCookie=" + store.state.username + ";expires=" + expire.toUTCString();
    document.cookie = "refreshCookie=" + updatedTokens.refreshToken + ";expires=" + expire.toUTCString();
}

export async function getCurrentUser() {
    let tokens = {};
    tokens.idToken = readSpecifiedCookie('idCookie');
    tokens.accessToken = readSpecifiedCookie('accessCookie');
    tokens.refreshToken = readSpecifiedCookie('refreshCookie');
    tokens.currentUser = readSpecifiedCookie('usernameCookie');
    console.log('local tokens', tokens);
    if (tokens.idToken === 'not found'
        || tokens.accessToken === 'not found'
        || tokens.refreshToken === 'not found'
        || tokens.currentUser === 'not found'
        || tokens.idToken === ''
        || tokens.accessToken === ''
        || tokens.refreshToken === ''
        || tokens.currentUser === ''
    ) {
        console.log('return false for local get current user', tokens);
        return false;
    }


    // TODO - timer based check for refreshing user, also check on each request for 
    // proper authentication

    // let verifyResponse = await verifyCurrentTokens(tokens);
    // if (!verifyResponse) {
    //     if (!(await refreshUser())) {
    //         return false;
    //     }
    // }
    // store.state.currentUser = tokens.currentUser;
    return tokens;
}

export function logout() {
    console.log('logging out...');
    document.cookie.split(";").forEach(function (c) { document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/"); });
    // store.state.idToken = '';
    // store.state.accessToken = '';
    // store.state.refreshToken = '';
    // store.state.currentUser = '';
    // store.state.fullPageModalActive = true;
    router.push('/LoginPage');
}