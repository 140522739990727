<template>
    <div class="section-holder">
        <div class="row" v-for="(field, idx) in data" :key="field.label">
            <div class="input-holder">
                <label
                    class="input-label"
                    :autofocus="idx === 0 ? true : false"
                >
                    {{ field.label }}{{ field.required ? "*" : "" }}
                </label>
                <input
                    :type="field.type ? field.type : ''"
                    class="input-field"
                    v-on:input="validateIncomingData($event, field)"
                    @paste.prevent
                    v-model="field.value"
                />
                <div class="flag-section">{{ field.flag }}</div>
            </div>
            <div class="hint">
                {{ field.hint && field.flag ? field.hint : "" }}
            </div>
        </div>
    </div>
</template>

<script>
let regExp = /^/;
export default {
    name: "AccountStep",
    props: {
        data: {
            type: Object,
            default: null,
            required: true,
        },
    },
    methods: {
        /**
         * Continually tests all values against specified regex expressions.
         * Sets flag values to whatever the fail case is.
         */
        validateIncomingData(val, field) {
            if (field.value.length === 0) {
                field.flag = "";
                return;
            }
            if (field.label !== "Email" && field.value.length > 15) {
                field.flag = "Too Long";
                return;
            }
            if (field.minLength && field.value.length < field.minLength) {
                field.flag = "Too Short";
                return;
            } else {
                field.flag = "";
            }

            if (
                field.regex &&
                field.regex !== "*" &&
                field.type !== "password"
            ) {
                // filter based on password in regex statemet
                regExp = field.regex;
                if (!regExp.test(field.value)) {
                    // failed regex test
                    field.flag = "invalid input";
                    return;
                }
            }
            // special case password check, lame, special cases are lame!
            if (field.type === "password") {
                regExp = field.regex;
                // console.log
                if (regExp.test(field.value)) {
                    // good password
                } else {
                    field.flag = "Stronger!";
                    return;
                }
            }
            field.flag = "";
        },
    },
};
</script>

<style scoped>
.input-holder {
    display: grid;
    grid-template-columns: 1fr;
    text-align: center;
    align-items: center;
    align-content: center;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 6px;
}
.center-grid-items {
    grid-column: 1/-1;
}
.section-holder {
    border: none;
    align-self: center;
    padding: 0;
    background-color: rgba(0, 0, 0, 0.69);
}
.row {
    grid-template-columns: 1fr;
    text-align: center;
    align-items: center;
    align-content: center;
}
.flag-section {
    height: 17px;
    font-family: inherit;
    font-size: 0.8rem;
    color: #fff;
    background: transparent;
    transition: border-color 0.2s;
}
.hint {
    font-family: inherit;
    font-size: 0.7rem;
    color: #fff;
    background: transparent;
    transition: border-color 0.2s;
}
.input-field {
    font-family: inherit;
    border: 0;
    border-bottom: 2px solid #9b9b9b;
    outline: 0;
    font-size: 1.3rem;
    color: #fff;
    padding: 7px 0;
    background: transparent;
    transition: border-color 0.2s;
    text-align: center;
}
.input-label {
    font-size: 1.4rem;
    font-weight: 600;
    color: #f4f4f4;
}
</style>
