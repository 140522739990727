<template>
    <div id="Dashboard">
        <nav class="navbar">
            <div class="dropdown topnav-left">
                <!-- to="/Dashboard/HomeDropdown" -->
                <button
                    v-on:click="this.dropdownMenuToggled"
                    id="toggleDropdownMenu"
                    class="dropbtn"
                >
                    <v-icon :icon="'bars'"></v-icon>
                </button>
                <PrimeDropdownMenu
                    v-click-outside="closeDropdown"
                    v-if="primeDropdownStatus"
                />
            </div>
            <div class="topnav-right">
                <button
                    v-on:click="toggleSettingsDropdown"
                    id="toggleDropdownMenu"
                    class="dropbtn"
                >
                    <v-icon :icon="'user-cog'" scale="1.5"></v-icon>
                    <Dropdown
                        :alignment="'right'"
                        :dropdownList="settingsDropdownItems"
                        @selectedDropdownItem="selectedDropdownItem"
                        v-if="settingsDropdownStatus"
                    />
                </button>

                <!-- <v-icon
                    class="settings-btn"
                    name="user-cog"
                    scale="1.5"
                    style="color: black"
                    ><Dropdown
                        :dropdownList="settingsDropdownItems"
                        v-if="settingsDropdownStatus"
                /></v-icon> -->

                <!-- <a
                    id="logout-button-navbar"
                    @click="triggerLogout"
                    class="logout-btn"
                    href=""
                    ><Dropdown
                        :dropdownList="settingsDropdownItems"
                        v-if="settingsDropdownStatus"
                    />
                </a> -->

                <!-- <a
                    id="logout-button-navbar"
                    @click="triggerLogout"
                    class="logout-btn"
                    href=""
                    >Logout</a
                > -->
            </div>
        </nav>
        <router-view :target="activeComponent"></router-view>
        <!-- just use router and not use these many v-if modals and pages -->
        <!-- <div>
    </div>
    <div>
        <StatsPage v-if="statsPageOpen" />
    </div>
    <div>
        <QuickAddModal v-if="quickAddModalOpen" />
    </div> -->
        <component :is="selectedModal" />
    </div>
</template>

<script>
import CompanyEditModal from "./CompanyEditModal.vue";
// import AccountEditModal from "./AccountEditView.vue";
import LoginModal from "./account/LoginModal.vue";
import HelpPage from "./HelpPage.vue";
import QuickAddModal from "./QuickAddModal.vue";
import ProductView from "./ProductView.vue";
import Product from "./Product.vue";
import Dropdown from "./Shared/Dropdown.vue";
import ProductEditModal from "./ProductEditModal.vue";
import TransactionsView from "./TransactionsView.vue";
import TransactionView from "./TransactionView.vue";
import PrimeDropdownMenu from "./PrimeDropdownMenu.vue";
// import TransactionServices from '../services-frontend/transactionServices';
import { getTransactions } from "../services-frontend/transactionServices";
import { getProducts } from "../services-frontend/product-services";
import { useStore } from "vuex";
// import 'vue-awesome/icons';
// import Icon from 'vue-awesome/components/Icon';
import { readSpecifiedCookie, getCurrentUser, logout } from "../auth/auth";

export default {
    name: "Dashboard",
    setup() {
        const store = useStore();
    },
    components: {
        LoginModal,
        PrimeDropdownMenu,
        HelpPage,
        CompanyEditModal,
        // AccountEditModal,
        // StatsPage,
        TransactionsView,
        QuickAddModal,
        ProductView,
        Product,
        Dropdown,
        // 'v-icon': Icon,
    },
    data() {
        return {
            activeComponent: "",
            loginModalOpen: true,
            primeDropdownStatus: false,
            primeToggleJustClicked: false,
            modalOpen: false,
            HelpPageOpen: false,
            accountModalOpen: false,
            companyModalOpen: false,
            statsPageOpen: false,
            transactionsPageOpen: false,
            // quickAddModalOpen: false,
            productsInView: [],
            products: [],
            loggedInUser: "",
            showProductLoadingIcon: false,
            itemsPerRow: 4,
            dropdownStatus: 0,
            selectedModal: "",
            settingsDropdownStatus: false,
            settingsDropdownItems: {
                account: {
                    title: "Account",
                    icon: "user-cog",
                    scale: "1.5",
                    "icon-style": "color: black",
                    triggers: "/settings/account",
                },
                // company: {
                //     title: "Company",
                //     icon: "building",
                //     scale: "1.5",
                //     "icon-style": "color: black",
                //     triggers: "/settings/company",
                // },
                help: {
                    title: "help",
                    icon: "user-cog",
                    scale: "1.5",
                    "icon-style": "color: black",
                    triggers: "/settings/account",
                },
                logout: {
                    title: "Logout",
                    icon: "question-circle",
                    scale: "1.5",
                    "icon-style": "color: black",
                    triggers: "close", // caught earlier in process just to close dropdown modal
                },
            },
        };
    },
    computed: {},
    async mounted() {
        await this.runner();
        // await this.$nextTick(this.runner);    // previously used to be in beforeMount
        console.log("added event listener for last route");
        window.addEventListener("unload", this.getLastRoute);
    },
    watch: {
        // "store.state.accessToken": function (newVal, oldVal) {
        //     console.log("accessToken " + newVal + " - " + oldVal);
        // },
        // "store.state.idToken": function (newVal, oldVal) {
        //     console.log("idToken " + newVal + " - " + oldVal);
        // },
        // "store.state.refreshToken": function (newVal, oldVal) {
        //     console.log("refreshToken " + newVal + " - " + oldVal);
        // },
    },
    methods: {
        // TODO - implement router redirecting!
        /**
         * incoming items selected by user from the Dropdown component
         *
         */
        selectedDropdownItem(item) {
            console.log("dropdown clicked");
            if (item === "Logout") {
                this.triggerLogout();
            }
        },
        toggleSettingsDropdown() {
            this.settingsDropdownStatus = this.settingsDropdownStatus
                ? false
                : true;
        },
        closeDropdown() {},
        getLastRoute() {
            console.log("page reloaded, setting pageReload var to true!");
            this.$store.dispatch('SET_PAGE_RELOAD_STATE', true);
            // store.state.pageReload = true;
        },
        addProduct(state, product) {
            // store.state.products.push(newProd);
            // store.state.products
        },
        // itemCountInRow: function (index) {
        //     return Object.keys(store.state.products).slice(
        //         (index - 1) * this.itemsPerRow,
        //         index * this.itemsPerRow
        //     ); // InView

        //     // return store.state.products.slice(
        //     //     (index - 1) * this.itemsPerRow,
        //     //     index * this.itemsPerRow
        //     // );
        // },
        displaySuccessMsg() {
            // TODO - implement!
        },
        triggerLogout() {
            document.cookie.split(";").forEach(function (c) {
                document.cookie = c
                    .replace(/^ +/, "")
                    .replace(
                        /=.*/,
                        "=;expires=" + new Date().toUTCString() + ";path=/"
                    );
            });
            this.loggedInUser = "";
            this.loginModalOpen = true;
            // store.state.idToken = "";
            // store.state.accessToken = "";
            // store.state.refreshToken = "";
            // store.state.currentUser = "";
            console.log("attempting to logout...");
            logout();
        },

        // const validRoutes = ['/Transactions','/Products'];

        async runner() {
            console.log("launching dashboard runner");

            // should no longer be needed...
            if (await getCurrentUser()) {
                let promise = this.loggedInGetStuff();
                this.resolvePageReloadRoute();
                this.loginModalOpen = false;
                await Promise.resolve(promise);
                // console.log("Cookies", store.state.cookieArray);
            } else {
                this.$router.push("/login").catch(() => {});
                this.loginModalOpen = true;
            }
        },
        // get10SecondsFromNow() { // NOT USED
        //     let today = new Date().toISOString();
        //     let nowPlus10 = new Date();
        //     nowPlus10.setDate(nowPlus10.getSeconds() + 10); // NOTE TODO *********** set to 100 for testing purposes
        //     nowPlus10.toISOString();
        //     return nowPlus10;
        // },
        resolvePageReloadRoute() {
            if (this.$store.getters.pageReload) {
                this.$store.dispatch('SET_PAGE_RELOAD_STATE', false)
                this.$router.push(localStorage.getItem("lastRoute"));
            } else {
                this.$router.push("/products");
            }
        },
        async loggedInGetStuff() {
            let promises;
            try {
                promises = [
                    this.getAccountSettings(),
                    this.getCompanySettings(),
                    getProducts(),
                    // this.getInitialTransactions(),
                ];
            } catch (err) {
                console.error(err);
            }
            await Promise.all(promises);

            // let data = await Promise.all(promises);
            // store.dispatch("SET_PRODUCTS", data[2]);
        },
        async getInitialTransactions() {
            let cookieCode = await readSpecifiedCookie("idCookie");
            let usernameCode = await readSpecifiedCookie("usernameCookie");
            let transactions = {};
            if (cookieCode === "error" && cookieCode !== "not found") {
                return false;
            }
            let today = new Date().toISOString();
            let twoDaysAgo = new Date();
            twoDaysAgo.setDate(twoDaysAgo.getDate() - 100); // NOTE TODO *********** set to 100 for testing purposes
            twoDaysAgo.toISOString();
            try {
                transactions = await getTransactions(today, twoDaysAgo, null);
            } catch (err) {
                console.log("ERROR gettings transactions", transactions);
            }

            if (transactions && transactions.length) {
                // store.state.transactions.push(...transactions);
                return true;
            }
            return false;
        },
        async getAccountSettings() {
            let cookieCode = readSpecifiedCookie("idCookie");
            let usernameCode = readSpecifiedCookie("usernameCookie");
            let res = {};
            if (cookieCode == "error" && cookieCode != "not found") {
                return false;
            }

            try {
                // console.log(
                //     "attempting query: " +
                //         "https://fnvrlfc2j4.execute-api.us-east-1.amazonaws.com/dev?idToken=" +
                //         store.state.idToken +
                //         "&username=" +
                //         store.state.currentUser
                // );
                // // TODO - update to actual account settings api gateway
                // res = await fetch(
                //     "https://fnvrlfc2j4.execute-api.us-east-1.amazonaws.com/dev?idToken=" +
                //         store.state.idToken +
                //         "&username=" +
                //         store.state.currentUser
                // ).then(
                //     await function (response) {
                //         if (response.ok) {
                //             return response.json();
                //         }
                //         return false;
                //     }
                // );
            } catch (err) {
                console.error(err);
                return false;
            }

            // save all settings to localStorage

            if (res !== false && res !== "false") {
                // update account settings within store here
                return true;
            } else {
                console.log("failed getting company settings");
                return false;
            }
        },
        async getCompanySettings() {
            // TODO - update to current standards you fool!

            let cookieCode = await readSpecifiedCookie("idCookie");
            let usernameCode = await readSpecifiedCookie("usernameCookie");
            let res = {};
            if (cookieCode == "error" && cookieCode != "not found") {
                return false;
            }

            try {
                // res = await fetch(
                //     "https://fnvrlfc2j4.execute-api.us-east-1.amazonaws.com/dev?idToken=" +
                //         store.state.idToken +
                //         "&username=" +
                //         store.state.currentUser
                // ).then(
                //     await function (response) {
                //         if (response.ok) {
                //             return response.json();
                //         }
                //         return false;
                //     }
                // );
            } catch (err) {
                console.error(err);
                return false;
            }

            if (res !== false && res !== "false") {
                // update company settings within store here
                return true;
            } else {
                console.log("failed getting company settings");
            }
            return false;
        },

        closeAnyOpenModal() {
            this.primeToggleJustClicked = false;
            this.loginModalOpen = false;
            this.primeDropdownStatus = false;
            this.modalOpen = false;
            // this.HelpPageOpen = false;
            // this.statsPageOpen = false;
            this.transactionsPageOpen = false;
            // this.quickAddModalOpen = false;
        },
        dropdownMenuToggled: function () {
            this.primeDropdownStatus = this.primeDropdownStatus ? false : true;
        },
        dropdownOptionSelected(selection) {
            switch (selection) {
                case "login":
                    this.loginModalOpen
                        ? (this.loginModalOpen = false)
                        : (this.loginModalOpen = true);
                    break;
                case "products":
                    // this.transactionsPageOpen ? this.transactionsPageOpen = false: this.transactionsPageOpen = true;
                    this.$router.push("/products");
                    break;
                case "transactions":
                    // this.transactionsPageOpen ? this.transactionsPageOpen = false: this.transactionsPageOpen = true;
                    this.$router.push("/transactions"); // Dashboard/
                    break;
                // case "account":
                //     if (this.accountModalOpen) {
                //         // modal is current open and being closed, wipe out contents of modal
                //     }
                //     this.accountModalOpen
                //         ? (this.accountModalOpen = false)
                //         : (this.accountModalOpen = true);
                //     break;
                // case "company":
                //     this.companyModalOpen
                //         ? (this.companyModalOpen = false)
                //         : (this.companyModalOpen = true);
                //     break;
                // case "stats":
                //     this.statsPageOpen
                //         ? (this.statsPageOpen = false)
                //         : (this.statsPageOpen = true);
                //     break;
                // case "help":
                //     this.helpPageOpen
                //         ? (this.helpPageOpen = false)
                //         : (this.helpPageOpen = true);
                //     break;
                case "/newProduct":
                    // this.quickAddModalOpen
                    //     ? (this.quickAddModalOpen = false)
                    //     : (this.quickAddModalOpen = true);
                    break;
                case "product":
                    // this.productModalOpen
                    //     ? (this.quickAddModalOpen = false)
                    //     : (this.quickAddModalOpen = true);
                    this.selectedModal =
                        this.selectedModal === "Product" ? "" : "Product";

                    break;
                default:
                    console.log("dropdown error detected using", selection); // TODO - remove console log in prod
            }
        },
    },
    beforeMount() {
        // this.$nextTick(this.runner);
    },
    directives: {
        "click-outside": {
            bind: function (el, binding, vNode) {
                // Provided expression must evaluate to a function.
                if (typeof binding.value !== "function") {
                    const compName = vNode.context.name;
                    let warn = `[Vue-click-outside:] provided expression '${binding.expression}' is not a function, but has to be`;
                    if (compName) {
                        warn += `Found in component '${compName}'`;
                    }

                    console.warn(warn);
                }
                // Define Handler and cache it on the element
                const bubble = binding.modifiers.bubble;
                const handler = (e) => {
                    if (bubble || (!el.contains(e.target) && el !== e.target)) {
                        binding.value(e);
                    }
                };
                el.__vueClickOutside__ = handler;

                // add Event Listeners
                document.addEventListener("click", handler);
            },

            unbind: function (el, binding) {
                // Remove Event Listeners
                document.removeEventListener("click", el.__vueClickOutside__);
                el.__vueClickOutside__ = null;
            },
        },
    },
};
</script>

<style>
#app {
    font-family: "Avenir", Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
}

.navbar {
    /* position: fixed; */
    overflow: visible;
    background-color: #3b175c;
    font-family: Arial, Helvetica, sans-serif;
    border-radius: 0px;
    width: 100%;
    height: 54px; /* verify this on different dislays */
}
.navbar a {
    font-size: 16px;
    color: white;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
}
.navbar btn {
    float: left;
    font-size: 16px;
    color: white;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    height: 54px;
}
.navbar .logout-btn {
    text-align: center;
    height: inherit;
}
.logout-btn {
    text-align: center;
    float: right;
}
.navbar .settings-btn {
    text-align: center;
    height: inherit;
}
.settings-btn {
    text-align: center;
    float: right;
}
.topnav-left {
    float: left;
}
.topnav-center {
    float: center;
}
.topnav-right {
    float: right;
}
.center {
    margin: auto;
    text-align: center;
    width: 50%;
    padding: 10px;
}
.navbar a:hover,
.dropdown:hover .btn,
.btn:focus {
    color: #ebf2ff;
}

.modal-background {
    width: 100%;
    height: 100%;
}

.dropbtn {
    background-color: #3b175c;
    color: #ebf2ff;
    padding: 16px;
    font-size: 16px;
    border: none;
    cursor: pointer;
    height: 54px;
}

.main {
    margin-left: 160px; /* Same as the width of the sidenav */
    font-size: 28px; /* Increased text to enable scrolling */
}

@media screen and (max-height: 450px) {
    .sidenav {
        padding-top: 15px;
    }
    .sidenav a {
        font-size: 18px;
    }
}

@keyframes shake {
    0% {
        transform: translate(1px, 1px) rotate(0deg);
    }
    10% {
        transform: translate(-1px, -2px) rotate(-1deg);
    }
    20% {
        transform: translate(-3px, 0px) rotate(1deg);
    }
    30% {
        transform: translate(3px, 2px) rotate(0deg);
    }
    40% {
        transform: translate(1px, -1px) rotate(1deg);
    }
    50% {
        transform: translate(-1px, 2px) rotate(-1deg);
    }
    60% {
        transform: translate(-3px, 1px) rotate(0deg);
    }
    70% {
        transform: translate(3px, 1px) rotate(-1deg);
    }
    80% {
        transform: translate(-1px, -1px) rotate(1deg);
    }
    90% {
        transform: translate(1px, 2px) rotate(0deg);
    }
    100% {
        transform: translate(1px, -2px) rotate(-1deg);
    }
}
</style>
