<template>
    <div class="background">
        <div class="dropdown-content" :class="'apply-' + alignment">
            <div
                class="dropdown-row"
                v-for="item in Object.keys(dropdownList)"
                :key="item.title"
                v-on:click="toggled(dropdownList[item].title)"
            >
                <v-icon
                    class="center-icon"
                    :name="dropdownList[item].icon"
                    :scale="dropdownList[item].scale"
                ></v-icon>
                <div class="dropdown-title">{{ dropdownList[item].title }}</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "HomeDropdown",
    props: {
        dropdownList: {
            type: Object,
            required: true,
        },
        alignment: {
            type: String,
            default: "inline", // inline, right, left
        },
    },
    methods: {
        toggled(val) {
            this.$emit("selectedDropdownItem", val);
        },
    },
};
</script>

<style scoped>
.dropdown {
    position: relative;
    display: inline-block;
}

/* mobile */
@media (max-width: 670px) {
    .dropdown-content {
        position: absolute;
        background-color: #f1f1f1;
        min-width: 220px;
        width: 100%;
        z-index: 101;
    }
}

/* desktop */
@media (min-width: 670px) {
    .dropdown-content {
        position: absolute;
        background-color: #f1f1f1;
        min-width: 220px;
        z-index: 101;
        /* right: 0%; displays right-hand side in viewport */
    }
}

.apply-left {
    right: 100%;
}

.apply-right {
    right: 0%;
}

.apply-inline {
    /* none required */
}

.dropdown-row {
    background-color: #3b175c;

    display: grid;
    grid-gap: 1em;
    grid-template-columns: 1fr 2fr;
    text-align: center;
    align-items: center;
    align-content: center;
    transition-property: background, border-radius;
    transition-duration: 0.2s, 1s;
    transition-timing-function: linear, ease-in;
    transition-delay: 0s, 1s;
    height: 54px;
}
.dropdown-row:hover {
    cursor: pointer;
    background: #bdd8f2;
}

.dropdown > .dropdown-title {
    width: 100%;
    text-align: center;
    font-size: 25px;
}
.dropdown > .dropdown-icon {
    text-align: center;
}

.center-icon {
    align-self: center;
    justify-self: center;
}
.dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}
.dropdown-font {
    float: right;
}
.sideBox {
    display: inline-block;
    padding: 6px 8px 6px 16px;
    text-decoration: none;
    font-size: 25px;
    display: block;
}
.escape--row {
    grid-column: 1/-1;
    background-color: #f03a44;
    color: #ebf2ff;
}
.dropdown-listing {
    display: grid;
    grid-gap: 1em;
    grid-template-columns: 1fr 3fr;
    text-align: center;
    align-items: center;
    align-content: center;
}
</style>
